import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Alert,
    AlertTitle,
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import isStrongPassword from 'validator/lib/isStrongPassword';

import Button from '../../components/UI/Button';
import { formatEndpoint, postData } from '../../utils/api';
import { ROUTES } from '../../constants';
import { API } from '../../config/api';
import AuthPaper from '../../components/AuthPaper';
import { extraTypography } from '../../themes/mui';

const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState<string | undefined>();
    const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [resetError, setResetError] = useState(false);

    const navigate = useNavigate();
    const { id, token } = useParams();

    const sendPasswordReset = async () => {
        const payload = { password };
        const endpoint = formatEndpoint(API.auth.submit_reset_password, { id, token });

        setSubmitted(true);
        try {
            await postData(endpoint, payload);
            setResetSuccess(true);
        } catch (err) {
            setResetError(true);
        }
    };

    useEffect(() => {
        // if password is now valid, turn off error
        // conversely, if password is now invalid, turn on error
        if (password && isStrongPassword(password, { minSymbols: 0 }) && passwordError) {
            setPasswordError(false);
        } else if (!password || (!isStrongPassword(password, { minSymbols: 0 }) && !passwordError)) {
            setPasswordError(true);
        }
        if (password && confirmPassword && password !== confirmPassword) {
            setConfirmPasswordError(true);
        }
        if (password && confirmPassword && password === confirmPassword) {
            setPasswordError(false);
            setConfirmPasswordError(false);
            setFormValid(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, confirmPassword]);

    useEffect(() => {
        setPasswordError(false);
        setConfirmPasswordError(false);
        setFormValid(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (submitted && resetError) {
        return (
            <AuthPaper>
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Something went wrong</AlertTitle>
                        Unfortunately we were unable to reset your password. Please contact your system
                        administrator for support.
                    </Alert>
                </Box>
            </AuthPaper>
        );
    }

    if (submitted && resetSuccess) {
        return (
            <AuthPaper>
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Alert variant="filled" severity="success" sx={{ marginTop: '2rem' }}>
                        <AlertTitle>Password successfully reset</AlertTitle>
                        Your password has been reset, you can now log in using your new password
                    </Alert>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate(ROUTES.LOGIN)}
                        sx={{ marginTop: '4rem' }}
                        black
                    >
                        Back to login
                    </Button>
                </Box>
            </AuthPaper>
        );
    }

    return (
        <AuthPaper>
            <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                 marginTop="6rem">
                <Box display="flex" flexDirection="column" alignItems="center" marginBottom="2rem">
                    <Typography sx={{ ...extraTypography.menu1, marginBottom: '.5rem' }}>
                        Reset your password
                    </Typography>
                    <Alert variant="filled" severity="info">
                        Your password must be at least 8 characters long, a mixture of both uppercase and
                        lowercase letters and a mixture of letters and numbers
                    </Alert>
                </Box>
                <FormControl variant="outlined" sx={{ marginBottom: '1rem', width: '100%' }}>
                    <InputLabel htmlFor="password-input" error={passwordError}>New password</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="new-password"
                        onChange={e => setPassword(e.target.value)}
                        onBlur={() => setPasswordError(password === undefined || password === '' || !isStrongPassword(password, { minSymbols: 0 }))}
                        error={passwordError}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="New Password"
                    />
                    <FormHelperText
                        error={passwordError}
                        id="password-helper-text"
                    >
                        {passwordError ? 'Please enter a valid password' : ' '}
                    </FormHelperText>
                </FormControl>
                <FormControl variant="outlined" sx={{ marginBottom: '1rem', width: '100%' }}>
                    <InputLabel htmlFor="password-confirm-input" error={confirmPasswordError}>Confirm
                        password</InputLabel>
                    <OutlinedInput
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirm-password"
                        onChange={e => setConfirmPassword(e.target.value)}
                        onBlur={() => setConfirmPasswordError(confirmPassword !== password)}
                        error={confirmPasswordError}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm Password"
                    />
                    <FormHelperText
                        error={confirmPasswordError}
                        id="password-helper-text"
                    >
                        {confirmPasswordError ? 'Passwords do not match' : ' '}
                    </FormHelperText>
                </FormControl>
                <Button
                    variant="contained"
                    onClick={() => sendPasswordReset()}
                    disabled={submitted || passwordError || confirmPasswordError || !formValid}
                    sx={{ width: '200px', alignSelf: 'center' }}
                    black
                >
                    Reset password
                </Button>
            </Box>
        </AuthPaper>
    );
};

export default ResetPassword;