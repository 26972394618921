import React from 'react';
import { Box, Typography } from '@mui/material';

const Dashboard: React.FC = () => {

    return (
        <>
            <Typography variant="h1" textTransform="uppercase">Dashboard</Typography>
            <Box
                display="flex"
                flexWrap="wrap"
                gap={3}
                width="100%"
                marginTop="1rem"
                paddingRight="24px"
                paddingTop="24px"
            >
            </Box>
        </>
    );
};

export default Dashboard;