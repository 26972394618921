import { MutationCache, QueryClient } from "@tanstack/react-query";
import { AuthLogin } from "../types/Auth";

/**
 * Gets the auth state from local storage
 * @returns {AuthLogin | undefined} Object containing authentication
 */
export const loadAuthState = (): AuthLogin | undefined => {
    try {
        const serializedState = localStorage.getItem('auth');

        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

/**
 * Saves the incoming state to local storage
 */
export const saveAuthState = (state: AuthLogin | undefined) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('auth', serializedState);
        return serializedState;
    } catch (err) {
        return undefined;
    }
};

const MAX_STALE_TIME_MINS = 30;
const MAX_CACHE_TIME_HOURS = 24;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * MAX_STALE_TIME_MINS,
            cacheTime: 1000 * 60 * 60 * MAX_CACHE_TIME_HOURS,
        }
    }
});

export const mutationCache = new MutationCache();