import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Avatar,
    Box,
    CircularProgress,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import { Logout } from '@mui/icons-material';

import { ROUTES } from '../../constants';
import { logout } from '../../services/authService';
import useGetCurrentUser from '../../hooks/query/currentUser/useGetCurrentUser';

const AccountMenu: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    const { data: currentUser, isLoading } = useGetCurrentUser();


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ height: 60, width: 60 }}
            >
                <Avatar>
                    {isLoading ?
                        <CircularProgress /> : `${currentUser?.first_name.charAt(0).toUpperCase()}${currentUser?.last_name.charAt(0).toUpperCase()}`}
                </Avatar>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        minWidth: 200,
                        maxWidth: 300,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 24,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box padding="6px 16px">
                    <Typography variant="subtitle2"
                                fontWeight={700}>{`${currentUser?.first_name} ${currentUser?.last_name}`}</Typography>
                </Box>
                <Divider sx={{ margin: '8px 0' }} />
                <MenuItem onClick={() => navigate(ROUTES.MY_ACCOUNT)}>
                    <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => {
                        logout();
                        navigate(ROUTES.LOGIN);
                    }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
};

export default AccountMenu;