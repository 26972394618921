import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
    UiConfigField,
    UiConfigPage,
    UiConfigTab,
    UiConfigWidget,
    UI_CONFIG_TYPE,
} from "../../../types/UiConfig";
import UiConfigGridItems from "./UiConfigGridItems";
import useUpdateUiConfigMutation from "../../../hooks/query/uiConfig/useUpdateUiConfigMutation";

interface UiConfigGridProps {
    data: UiConfigPage;
}

const UiConfigGrid: React.FC<UiConfigGridProps> = ({ data }) => {
    const activePage = data;
    const [activeTab, setActiveTab] = useState<UiConfigTab | null>(null);
    const [activeWidget, setActiveWidget] = useState<UiConfigWidget | null>(
        null
    );
    const { mutate: updateUiConfig } = useUpdateUiConfigMutation();

    useEffect(() => {
        setActiveWidget(null);
    }, [activeTab]);

    const handleSwitchChange = (
        item: UiConfigTab | UiConfigWidget | UiConfigField,
        type: UI_CONFIG_TYPE
    ) => {
        updateUiConfig({
            type,
            data: item,
            enabled: !!item.enabled,
            activePage,
            activeTab,
            activeWidget,
        });
    };

    /**
     * Thank you MUI for having a useful way to make a grid, very easy to work with... NOT!
     * Need to use the workaround mentioned here https://stackoverflow.com/a/60468992/19336978
     */
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container direction="row" alignItems="flex-start" spacing={4}>
                <Grid item key="tabs">
                    <Typography variant="h2" component="div" gutterBottom>
                        Tabs
                    </Typography>
                    <Grid container direction="column" spacing={2}>
                        <UiConfigGridItems
                            type={UI_CONFIG_TYPE.TABS}
                            onButtonClick={(tab: UiConfigTab) =>
                                tab === activeTab
                                    ? setActiveTab(null)
                                    : setActiveTab(tab)
                            }
                            onSwitchChange={(tab: UiConfigTab) =>
                                handleSwitchChange(tab, UI_CONFIG_TYPE.TABS)
                            }
                            data={data?.tabs}
                        />
                    </Grid>
                </Grid>
                {activeTab && (
                    <React.Fragment key="widgets">
                        <Box paddingLeft={"20px"} paddingTop={"85px"}>
                            <ArrowForwardIcon />
                        </Box>
                        <Grid item>
                            <Typography
                                variant="h2"
                                component="div"
                                gutterBottom
                            >
                                Widgets
                            </Typography>
                            <Grid container direction="column" spacing={2}>
                                <UiConfigGridItems
                                    type={UI_CONFIG_TYPE.WIDGETS}
                                    onButtonClick={(widget: UiConfigWidget) =>
                                        widget === activeWidget
                                            ? setActiveWidget(null)
                                            : setActiveWidget(widget)
                                    }
                                    onSwitchChange={(widget: UiConfigWidget) =>
                                        handleSwitchChange(
                                            widget,
                                            UI_CONFIG_TYPE.WIDGETS
                                        )
                                    }
                                    data={activeTab?.widgets}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                {activeWidget && (
                    <React.Fragment key="fields">
                        <Box paddingLeft={"20px"} paddingTop={"85px"}>
                            <ArrowForwardIcon />
                        </Box>
                        <Grid item>
                            <Typography
                                variant="h2"
                                component="div"
                                gutterBottom
                            >
                                Fields
                            </Typography>
                            <Grid container direction="column" spacing={2}>
                                <UiConfigGridItems
                                    type={UI_CONFIG_TYPE.FIELDS}
                                    onSwitchChange={(field: UiConfigField) =>
                                        handleSwitchChange(
                                            field,
                                            UI_CONFIG_TYPE.FIELDS
                                        )
                                    }
                                    data={activeWidget?.fields}
                                />
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Box>
    );
};

export default UiConfigGrid;
