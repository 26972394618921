import React from 'react';
import { Alert, Dialog, DialogActions, DialogContent, Button, DialogTitle } from '@mui/material';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '../../UI/Button';
// import DialogTitle from '../../UI/DialogTitle';

interface ConfirmDeleteProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    modalTitle?: string;
    modalSubheading?: string;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({ open, onClose, onConfirm, modalTitle, modalSubheading }) => {
    return (
        <Dialog
            sx={{
                '& .MuiDialogContent-root': {
                    padding: '2rem'
                },
                '& .MuiDialogActions-root': {
                    padding: '1rem'
                }
            }}
            fullWidth={true}
            maxWidth={'md'}
            onClose={() => onClose()}
            open={open}
        >
            <DialogTitle>
                {modalTitle || 'Confirm delete'}
            </DialogTitle>
            <DialogContent dividers>
                <Alert variant="filled" severity="warning">
                    {modalSubheading || 'This action cannot be reversed'}
                </Alert>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'flex-end'
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => onClose()}
                    startIcon={<CancelIcon />}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color='error'
                    onClick={() => onConfirm()}
                    endIcon={<DeleteIcon />}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDelete;