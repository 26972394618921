import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Alert,
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    TextField,
    Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import isEmail from 'validator/lib/isEmail';

import { extraTypography } from '../../themes/mui';
import Button from '../../components/UI/Button';
import { ROUTES } from '../../constants';
import AuthPaper from '../../components/AuthPaper';
import { logout } from '../../services/authService';
import usePrevious from '../../hooks/usePrevious';
import useDebouncedEffect from '../../hooks/useDebouncedEffect';
import useLoginMutation from '../../hooks/query/auth/useLoginMutation';

const Login = () => {
    const [email, setEmail] = useState<string | undefined>();
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState<string | undefined>();
    const [passwordError, setPasswordError] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [displayLoginError, setDisplayLoginError] = useState(false);
    const [showAccountBlocked, setShowAccountBlocked] = useState(false);
    const [accountBlocked, setAccountBlocked] = useState(false);
    const [loginAttempted, setLoginAttempted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const previousEmail = usePrevious(email);
    const previousPassword = usePrevious(password);

    const navigate = useNavigate();
    const location: any = useLocation();

    const { isSuccess: isLoginSuccess, error: loginError, mutate: mutateLogin } = useLoginMutation(setAccountBlocked);

    const handleLogin = async () => {
        setLoginAttempted(true);
        setDisplayLoginError(false);
        setLoggingIn(true);
        if (email && password) {
            mutateLogin({ email, password });
        }
    };

    // clear errors while logging in
    useEffect(() => {
        if (loggingIn) {
            setFormValid(true);
            setEmailError(false);
            setPasswordError(false);
        }
    }, [loggingIn]);

    // handle whether the form is valid for submission
    useEffect(() => {
        if (email && password && !emailError && !passwordError) {
            setFormValid(true);
        }
    }, [email, password, emailError, passwordError]);

    // wiping out email or password should set on appropriate errors
    useEffect(() => {
        if (previousEmail && !email) {
            setEmailError(true);
            setFormValid(false);
        }
        if (previousPassword && !password) {
            setPasswordError(true);
            setFormValid(false);
        }
        if (!formValid) {
            // if email is now valid, turn off error
            // conversely turn on error if it is now invalid
            if (email && isEmail(email) && emailError) {
                setEmailError(false);
            } else if (!email || (email && !isEmail(email) && !emailError)) {
                setEmailError(true);
            }
            // if password is now entered, turn off error
            if (password && passwordError) {
                setPasswordError(false);
            }
        }
    }, [email, previousEmail, password, previousPassword, emailError, passwordError, formValid]);

    // handle login error
    useDebouncedEffect(() => {
        if (loginAttempted) {
            if (accountBlocked) {
                setShowAccountBlocked(true);
            } else if (loginError) {
                setDisplayLoginError(true);
                setEmailError(true);
                // setPasswordError(true);
            }
            setLoggingIn(false);
        }
    }, [loginError, accountBlocked], 1000);

    // handle success
    useDebouncedEffect(() => {
        if (isLoginSuccess) {
            navigate(location.state?.from || ROUTES.DASHBOARD);
        }
    }, [isLoginSuccess], 1000);

    useEffect(() => {
        logout();
        // turn off errors
        setEmailError(false);
        setPasswordError(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthPaper>
            {showAccountBlocked ? (
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Typography variant="h3" sx={{ marginBottom: '1rem' }}>Account Blocked</Typography>
                    <Typography variant="body1" textAlign="center" sx={{ marginBottom: '2rem' }}>
                        Unfortunately, your account has been blocked. Please contact your system administrator to reinstate your account.
                    </Typography>
                    <Alert variant="filled" severity="info">This usually happens if you enter an incorrect password too many times.</Alert>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => setShowAccountBlocked(false)}
                        sx={{ marginTop: '3rem' }}
                        black
                    >
                        Back to login
                    </Button>
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" padding="1rem" width="100%" marginTop="6rem">
                    <Typography
                        alignSelf="center"
                        sx={{ ...extraTypography.menu1, marginBottom: '2rem' }}
                    >
                        Syfter Admin Portal
                    </Typography>
                    <TextField
                        type="email"
                        id="email"
                        label="Email"
                        variant="outlined"
                        onChange={e => setEmail(e.target.value)}
                        onBlur={() => setEmailError(email ? !isEmail(email) : true)}
                        error={emailError}
                        helperText={displayLoginError ? 'Your login credentials are incorrect. Please try again' : emailError ? 'Please enter a valid email address' : ' '}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="password-input"
                                    error={passwordError || displayLoginError}>Password</InputLabel>
                        <OutlinedInput
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            onChange={e => setPassword(e.target.value)}
                            onKeyPress={e => {
                                if (e.key === 'Enter') handleLogin();
                            }}
                            onBlur={() => setPasswordError(password === undefined || password === '')}
                            error={passwordError || displayLoginError}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        <FormHelperText
                            id="password-helper-text"
                            error={passwordError || displayLoginError}
                        >
                            {displayLoginError ? ' ' : passwordError ? 'Please enter a valid password' : ' '}
                        </FormHelperText>
                    </FormControl>
                    <Link
                        underline="none"
                        component="button"
                        variant="body2"
                        onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
                        sx={{ alignSelf: 'flex-end', marginBottom: '4rem', marginTop: '-10px' }}
                    >
                        Forgot your password?
                    </Link>
                    <Button
                        variant="contained"
                        onClick={async () => await handleLogin()}
                        disabled={!formValid || loggingIn}
                        sx={{ width: '126px', alignSelf: 'center' }}
                        black
                    >
                        {loggingIn ? <CircularProgress size={25} /> : `Login`}
                    </Button>
                </Box>
            )}
        </AuthPaper>
    );
};

export default Login;