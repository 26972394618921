import { useMutation } from "@tanstack/react-query";
import ServiceKeys from "../../../services/serviceKeys";
import * as authService from '../../../services/authService';
import { ServerError, UserErrorCodes } from "../../../types/Error";
import { queryClient, saveAuthState } from "../../../config/localStorage";

const useLoginMutation = (setAccountBlocked: (flag: boolean) => void) => {
    return useMutation({
        mutationFn: async ({ email, password }: { email: string, password: string }) => await authService.login(email, password),
        onSuccess: (data) => {
            setAccountBlocked(false);
            queryClient.setQueryData([ServiceKeys.Auth], data);
            saveAuthState(data);
        },
        onError: (error: ServerError) => {
            if (error.response.data.statusCode === UserErrorCodes.Forbidden) {
                setAccountBlocked(true);
            }
            queryClient.setQueryData([ServiceKeys.Auth], error);
        }
    });
}
export default useLoginMutation;