import React, { useState } from "react";
import {
    Typography,
    Box,
    Select,
    FormControl,
    InputLabel,
    Button,
    SelectChangeEvent,
    FormGroup,
    Stack,
    CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
    DataGridPro,
    useGridApiRef,
    GridActionsCellItem,
    GridColumns,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridEvents,
    GridRowModel,
    GridToolbar,
    GridPreProcessEditCellProps,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { isEmpty } from "ramda";

import EditorDialog from "../../components/Modals/Editor";
import {
    providersListToMenuItems,
    providerRecordToColumns,
} from "../../components/UI/DataGrid";
import ConfirmDelete from "../../components/Modals/ConfirmDelete";
import useGetProviderDefinitions from "../../hooks/query/providers/useGetProviderDefinitions";
import useGetProviderData from "../../hooks/query/providers/useGetProviderData";
import useCreateProviderMutation from "../../hooks/query/providers/useCreateProviderMutation";
import useUpdateProviderMutation from "../../hooks/query/providers/useUpdateProviderMutation";
import useDeleteProviderMutation from "../../hooks/query/providers/useDeleteProviderMutation";

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .MuiFormGroup-options": {
        alignItems: "center",
        paddingBottom: theme.spacing(1),
        "& > div": {
            minWidth: 100,
            margin: theme.spacing(2),
            marginLeft: 0,
        },
    },
    "& .MuiDataGrid-cell--editing": {
        backgroundColor: "rgb(255,215,115, 0.19)",
        color: "#1a3e72",
        "& .MuiInputBase-root": {
            height: "100%",
        },
    },
    "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
        color: theme.palette.error.main,
        height: "100%",
    },
}));

const Providers: React.FC = () => {
    const apiRef = useGridApiRef();

    const [currentProviderName, setCurrentProviderName] = useState(
        "organisation_alt_signals"
    );
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogProviderId, setDeleteDialogProviderId] = useState(0);
    const [openJsonDialog, setOpenJsonDialog] = useState(false);
    const [jsonColumn, setJsonColumn] = useState<JSON | string>();
    const [jsonColumnId, setJsonColumnId] = useState(0);
    const [jsonColumnName, setJsonColumnName] = useState("");
    const [anyRowInEditMode, setAnyRowInEditMode] = useState(false);

    const {
        data: providersDefinitionsData,
        isLoading: providersDefinitionsPending,
    } = useGetProviderDefinitions();
    const { data: providerData, isLoading: providerDataPending } =
        useGetProviderData(currentProviderName);
    const { mutate: mutateCreateProvider } = useCreateProviderMutation(apiRef);
    const { mutate: mutateUpdateProvider } = useUpdateProviderMutation(apiRef);
    const { mutate: mutateDeleteProvider } = useDeleteProviderMutation();

    const isTableInEditMode = () => {
        const rowIds = apiRef.current.getAllRowIds();
        // Returns true if any of the rows are in edit mode
        return rowIds
            .map((id) => apiRef.current.getRowMode(id) === "edit")
            .some((row) => row);
    };

    const handleProviderDelete = (providerName: string, id: number) => {
        setOpenDeleteDialog(false);
        mutateDeleteProvider({ providerName, providerId: id });
        apiRef.current.updateRows([{ id, _action: "delete" }]);
    };

    const handleSaveJsonColumn = (
        jsonColumnName: string,
        jsonColumnId: number,
        payload: any
    ) => {
        setOpenJsonDialog(false);
        apiRef.current.setEditCellValue({
            id: jsonColumnId,
            field: jsonColumnName,
            value: payload,
        });
    };

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<GridEvents.rowEditStop> = (
        params,
        event
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => (event: React.MouseEvent) => {
        event.stopPropagation();
        apiRef.current.startRowEditMode({ id });
        setAnyRowInEditMode(true);
    };

    const handleSaveClick =
        (id: GridRowId) => async (event: React.MouseEvent) => {
            event.stopPropagation();
            await apiRef.current.stopRowEditMode({ id });
            setAnyRowInEditMode(isTableInEditMode());
        };

    const handleDeleteClick = (id: GridRowId) => (event: React.MouseEvent) => {
        event.stopPropagation();
        setDeleteDialogProviderId(id as number);
        setOpenDeleteDialog(true);
    };

    const handleCancelClick =
        (id: GridRowId) => async (event: React.MouseEvent) => {
            event.stopPropagation();
            await apiRef.current.stopRowEditMode({
                id,
                ignoreModifications: true,
            });

            const row = apiRef.current.getRow(id);
            if (row?.isNew) {
                apiRef.current.updateRows([{ id, _action: "delete" }]);
            }
            setAnyRowInEditMode(isTableInEditMode());
        };

    const processRowUpdate = async (rowData: GridRowModel) => {
        // If the row has just been created then the row will have a property of isNew = true
        // so can use that as a way to decide whether to fire off a POST or PUT request to the database.
        const { isNew } = rowData;
        if (isNew) {
            mutateCreateProvider({
                providerName: currentProviderName,
                provider: rowData,
            });
            // Return the last row from the provider as we have just deleted the current row
            return { ...rowData, isNew: true };
        }
        mutateUpdateProvider({
            providerName: currentProviderName,
            provider: rowData,
        });
        return { ...rowData, isNew: false };
    };

    const handleNewRow = () => {
        // Need to get all the rows and if there aren't any currently then return an array of [0]
        const rowIds =
            apiRef.current.getAllRowIds().length > 0
                ? apiRef.current.getAllRowIds()
                : [0];
        const maxId = Math.max(...(rowIds as number[]));
        const id = maxId + 1;

        // Set some default values for the row to prevent MUI logging warnings
        apiRef.current.updateRows([
            {
                id,
                created_at: Date.now(),
                updated_at: Date.now(),
                enabled: false,
                isNew: true,
            },
        ]);
        apiRef.current.startRowEditMode({ id });

        // Wait for the grid to render with the new row
        setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: apiRef.current.getRowsCount() - 1,
            });
            apiRef.current.setCellFocus(id, "name");
        });
        setAnyRowInEditMode(true);
    };

    const columns: GridColumns = [
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = apiRef.current.getRowMode(id) === "edit";

                if (isInEditMode) {
                    return [
                        //@ts-ignore
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        //@ts-ignore
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    //@ts-ignore
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    //@ts-ignore
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        { field: "id", headerName: "id", width: 20 },
        {
            field: "name",
            headerName: "name",
            width: 180,
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError =
                    params.props.value === undefined ||
                    isEmpty(params.props.value);
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "created_at",
            headerName: "created at",
            width: 170,
            type: "date",
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: "updated_at",
            headerName: "updated at",
            width: 170,
            type: "date",
            valueGetter: ({ value }) => value && new Date(value),
        },
    ];

    if (providersDefinitionsPending) {
        return (
            <Box
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Typography variant="h1" component="span" textTransform="uppercase">
                Providers
            </Typography>
            <StyledBox>
                <FormGroup className="MuiFormGroup-options" row>
                    <FormControl
                        variant="standard"
                        sx={{ m: 1, maxWidth: 170 }}
                    >
                        <InputLabel id="select-providers-label">
                            Provider
                        </InputLabel>
                        <Select
                            disabled={anyRowInEditMode}
                            labelId="select-providers-label"
                            label="Provider"
                            value={currentProviderName}
                            onChange={(e: SelectChangeEvent) =>
                                setCurrentProviderName(e.target.value)
                            }
                        >
                            {providersListToMenuItems(
                                providersDefinitionsData?.providers_list || null
                            )}
                        </Select>
                    </FormControl>
                    <Stack spacing={2} direction="row">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleNewRow()}
                        >
                            <AddIcon fontSize="small" /> Add Row
                        </Button>
                    </Stack>
                </FormGroup>
                <Box
                    id={currentProviderName}
                    display="flex"
                    height="calc(85vh - 105px)"
                    width="100%"
                >
                    <DataGridPro
                        rows={providerData || []}
                        columns={
                            providerRecordToColumns(
                                currentProviderName,
                                providersDefinitionsData?.providers || null,
                                columns,
                                () => setOpenJsonDialog(true),
                                setJsonColumn,
                                setJsonColumnId,
                                setJsonColumnName
                            ) || []
                        }
                        loading={providerDataPending}
                        apiRef={apiRef}
                        editMode="row"
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        getRowId={(row) => row.id}
                        experimentalFeatures={{ newEditingApi: true }}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        initialState={{
                            pinnedColumns: { left: ["actions"] },
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                    />
                </Box>
            </StyledBox>
            <ConfirmDelete
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={() =>
                    handleProviderDelete(
                        currentProviderName,
                        deleteDialogProviderId
                    )
                }
                modalSubheading={`Are you sure you want to delete the ${currentProviderName.replace("organisation_", "").replaceAll("_", " ")} provider record with id ${deleteDialogProviderId}?`}
            />
            <EditorDialog
                open={openJsonDialog}
                onClose={() => setOpenJsonDialog(false)}
                onConfirm={(payload) =>
                    handleSaveJsonColumn(jsonColumnName, jsonColumnId, payload)
                }
                defaultLanguage="json"
                defaultValue={JSON.stringify(jsonColumn, null, "\t")}
                modalTitle={`JSON Editor (editing: ${jsonColumnName})`}
            />
        </>
    );
};

export default Providers;
