export type ServerError = {
    response: {
        data: Error;
    }
}


export type Error = {
    name: string;
    message: string;
    statusCode: UserErrorCodes & ServerErrorCodes;
    errorCode: UserErrorCodes & ServerErrorCodes;
    stash: object | string;
    stack: object | string;
    log: object;
}

export enum UserErrorCodes {
    BadRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    ProxyAuthenticationRequired = 407,
    RequestTimeOut = 408,
    Conflict = 409,
    Gone = 410,
    LengthRequired = 411,
    PreconditionFailed = 412,
    RequestEntityTooLarge = 413,
    RequestURLTooLarge = 414,
    UnsupportedMediaType = 415,
    FailedDependency = 424
}

export enum ServerErrorCodes {
    ServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    OutOfResources = 503,
    GatewayTimeOut = 504
}