import { CHILD_ROUTES } from '../constants';

/**
 * Returns true if current route matches the comparison route, or the current route is a child of the comparison route
 * @param {*} currentRoute the current route in the URL
 * @param {*} comparisonRoute the route we want to compare it to
 */
export const isActiveRoute = (currentRoute: string, comparisonRoute: string) => {
    if (currentRoute === comparisonRoute) return true;
    const childRoute = CHILD_ROUTES.find(cr => '/' + cr.route.split('/')[1] === currentRoute);
    return !!(childRoute && childRoute.parent === comparisonRoute);
};