import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import * as monaco from "monaco-editor";
import Editor, { loader } from "@monaco-editor/react";

interface EditorDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (payload: any) => void;
    defaultLanguage: string;
    defaultValue: any;
    modalTitle?: string;
}

const EditorDialog: React.FC<EditorDialogProps> = ({
    open,
    onClose,
    onConfirm,
    defaultLanguage,
    defaultValue,
    modalTitle,
}) => {
    const [data, setData] = useState<string | undefined>("");

    // This loads the monaco editor via webpack instead of via CDN. Needed to get around the CSP issues.
    loader.config({ monaco });

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => onClose()}
                aria-labelledby="json-dialog-title"
                aria-describedby="json-dialog-description"
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle id="json-dialog-title">
                    {modalTitle || "Editor"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="json-dialog-description">
                        <Editor
                            height="75vh"
                            width="auto"
                            defaultLanguage={defaultLanguage}
                            defaultValue={defaultValue}
                            onChange={(value) => setData(value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => onClose()}
                        startIcon={<DeleteIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => onConfirm(data)}
                        endIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditorDialog;
