import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import ServiceKeys from "../../../services/serviceKeys";
import * as uiConfigService from '../../../services/uiConfigService';
import { ServerError } from "../../../types/Error";
import { queryClient } from "../../../config/localStorage";
import { UpdateUiConfig } from "../../../types/UiConfig";

const useUpdateUiConfigMutation = () => {
    const { enqueueSnackbar } = useSnackbar();
    
    return useMutation({
        mutationFn: async ({ type, data, enabled, activePage, activeTab, activeWidget }: UpdateUiConfig) => {
            await uiConfigService.updateUiConfig(type, data, enabled, activePage, activeTab, activeWidget); 
        },
        onSuccess: () => {
            queryClient.invalidateQueries([ServiceKeys.UiConfigRenames]);
            enqueueSnackbar(`Successfully updated Ui config`, { variant: 'success' });
        },
        onError: (err: ServerError) => {
            enqueueSnackbar(`There was an error updating the Ui config ${err.response.data.log}`, { variant: 'error' });
        }
    });
}

export default useUpdateUiConfigMutation;