import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import ServiceKeys from '../../../services/serviceKeys';
import * as providersService from '../../../services/providersService';
import { ServerError } from '../../../types/Error';


const useGetProviderDefinitions = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery({
        queryKey: [ServiceKeys.ProvidersDefinitions],
        queryFn: providersService.providersDefinitions,
        onError: (err: ServerError) => {
            enqueueSnackbar(`An error occurred while fetching the providers definitions ${err.response.data.log}`, { variant: 'error' });
        },
        retry: false
    });
}

export default useGetProviderDefinitions;