import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Link, TextField, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Button from '../../components/UI/Button';
import { postData } from '../../utils/api';
import isEmail from 'validator/lib/isEmail';
import { ROUTES } from '../../constants';
import { API } from '../../config/api';
import AuthPaper from '../../components/AuthPaper';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState<string | undefined>();
    const [emailError, setEmailError] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const navigate = useNavigate();

    const sendPasswordReset = async () => {
        const payload = { email };
        const endpoint = API.auth.reset_password;

        setSubmitted(true);
        await postData(endpoint, payload);
    };

    useEffect(() => {
        if (email && isEmail(email) && emailError) {
            setEmailError(false);
        } else if (!email || (email && !isEmail(email) && !emailError)) {
            setEmailError(true);
        }
    }, [email, emailError]);

    useEffect(() => {
        // turn off errors
        setEmailError(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthPaper>
            {!submitted ? (
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Link
                        underline="none"
                        component="button"
                        variant="body2"
                        onClick={() => navigate(ROUTES.LOGIN)}
                        sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
                    >
                        <ArrowBackIcon />Back to login
                    </Link>
                    <Alert variant="filled" severity="info">
                        To reset your password, enter your email address. You will receive an email with
                        instructions on how to reset your password
                    </Alert>
                    <TextField
                        type="email"
                        id="email"
                        label="Email"
                        variant="outlined"
                        onChange={e => setEmail(e.target.value)}
                        onBlur={() => setEmailError(email ? !isEmail(email) : true)}
                        error={emailError}
                        helperText={emailError ? 'Please enter a valid email address' : ' '}
                        sx={{ margin: '2rem 0', width: '100%' }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => sendPasswordReset()}
                        disabled={emailError || !email}
                        sx={{ width: '200px', alignSelf: 'center' }}
                        black
                    >
                        Send reset link
                    </Button>
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-evenly"
                     padding="1rem" width="100%" height="200px" marginTop="2rem">
                    <Box display="flex" alignItems="center">
                        <MailOutlineIcon sx={{ marginRight: '5px' }} />
                        <Typography variant="h3">
                            Password reset sent!
                        </Typography>
                    </Box>
                    <Alert variant="filled" severity="info">
                        Please keep an eye on your inbox for your password reset link
                    </Alert>
                </Box>
            )}
        </AuthPaper>
    );
};

export default ForgotPassword;