import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import ServiceKeys from "../../../services/serviceKeys";
import * as providersService from '../../../services/providersService';
import { ServerError } from "../../../types/Error";
import { queryClient } from "../../../config/localStorage";

const useDeleteProviderMutation = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: async ({ providerName, providerId }: { providerName: string, providerId: number }) => {
            await providersService.deleteProvider(providerName, providerId);
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries([ServiceKeys.Providers, variables.providerName]);
            enqueueSnackbar(`Provider ${variables.providerName} record deleted`, { variant: 'success' });
        },
        onError: (err: ServerError, variables) => {
            enqueueSnackbar(`Failed to delete provider ${variables.providerName} record. ${err.response.data.log}`, { variant: 'error' });
        }
    });
}
export default useDeleteProviderMutation;