export const ROUTES = {
    ACCEPT_INVITE: '/accept-invite/:id/:token',
    BRANDING: '/branding',
    COMPANIES: '/companies',
    COMPANY_PROFILE: '/company/:id',
    CUSTOM_PROPERTIES_INDEX: '/custom-properties-index',
    DASHBOARD: '/dashboard',
    FORGOT_PASSWORD: '/forgot-password',
    LOGIN: '/login',
    MY_ACCOUNT: '/my-account',
    PROVIDERS: '/providers',
    RESET_PASSWORD: '/reset-password/:id/:token',
    ROLES: '/roles',
    SECTORS: '/sectors',
    SIGNALS: '/signals',
    UI_CONFIGURATION: '/ui-configuration',
    USER_MANAGEMENT: '/user-management',
    PLATFORM_SETTINGS: '/platform-settings'
};

export const CHILD_ROUTES = [
    {
        route: ROUTES.COMPANY_PROFILE,
        parent: ROUTES.COMPANIES
    }
];
