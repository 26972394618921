import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import ServiceKeys from "../../../services/serviceKeys";
import * as customPropertyService from '../../../services/customPropertiesService';
import { ServerError } from "../../../types/Error";
import { queryClient } from "../../../config/localStorage";
import { CustomProperty } from "../../../types/CustomProperty";

const useUpdateCustomPropertiesMutation = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: async ({ customProperties }: { customProperties: CustomProperty[] }) => {
            await customPropertyService.savePropertiesIndex(customProperties);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([ServiceKeys.CustomProperties]);
            enqueueSnackbar('Successfully updated custom properties', { variant: 'success' });
        },
        onError: (err: ServerError) => {
            queryClient.invalidateQueries([ServiceKeys.CustomProperties]);
            enqueueSnackbar(`An error occurred while updating custom properties ${err.response.data.log}`, { variant: 'error' });
        }
    });
}

export default useUpdateCustomPropertiesMutation;