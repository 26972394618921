import React from "react";
import {
    Box,
    IconButton,
    Link,
    MenuItem,
    Paper,
    Popper,
    Typography,
} from "@mui/material";
import {
    GridRenderCellParams,
    GridColumns,
    GridPreProcessEditCellProps,
    GridEnrichedColDef,
    GridRenderEditCellParams,
} from "@mui/x-data-grid";
import { isEmpty } from "ramda";
import { ProviderInfo } from "../../../types/Providers";
import EditIcon from "@mui/icons-material/Edit";

interface GridCellExpandProps {
    value: string;
    width: number;
}

const GridCellExpand = React.memo(function GridCellExpand(
    props: GridCellExpandProps
) {
    const { width, value } = props;
    const wrapper = React.useRef<HTMLDivElement | null>(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const isOverflown = (element: Element | null): boolean => {
        if (!element) return false;

        return (
            element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth
        );
    };

    const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setShowFullCell(false);
    };

    React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: 1,
                height: 1,
                position: "relative",
                display: "flex",
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: "block",
                    position: "absolute",
                    top: 0,
                }}
            />
            <Box
                ref={cellValue}
                sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </Box>
            {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{
                        width,
                        wordBreak: "break-word",
                        maxHeight: "500px",
                        overflow: "auto",
                    }}
                >
                    <Paper
                        elevation={1}
                        style={
                            wrapper.current
                                ? {
                                      minHeight:
                                          wrapper.current.offsetHeight - 3,
                                  }
                                : undefined
                        }
                    >
                        <Typography variant="body2" style={{ padding: 8 }}>
                            {value}
                        </Typography>
                    </Paper>
                </Popper>
            )}
        </Box>
    );
});

export const renderCellExpand = (params: GridRenderCellParams<string>) => {
    return (
        <GridCellExpand
            value={params.value || ""}
            width={params.colDef.computedWidth}
        />
    );
};

export const providersListToMenuItems = (providersList: string[] | null) => {
    if (providersList === null) {
        return (
            <MenuItem key="no data" value="no data">
                No data
            </MenuItem>
        );
    }
    const formattedProvidersList = providersList.map((provider) => {
        // The incoming list providers will look like organisation_duedil_ids so we need to remove the
        // organisation, underscores and take off the s at the end so that it's singular
        const formattedProvider = provider
            .replace("organisation_", "")
            .replaceAll("_", " ");
        return (
            <MenuItem key={provider} value={provider}>
                {formattedProvider}
            </MenuItem>
        );
    });

    return formattedProvidersList;
};

/**
 * Convert a string to json. If the value is null or an empty string then just return the string
 * @param value the string to convert
 * @returns JSON or string if error when parsing
 */
const stringToJson = (value: string): JSON | string => {
    try {
        return JSON.parse(value);
    } catch (err) {
        return value || "";
    }
};

/**
 * Converts JSON to string. If the data is already a string then just return that.
 * Needed to avoid JSON objects getting stringified over and over again.
 * @param value JSON or string (JSON expected but can handle strings)
 * @returns string
 */
const jsonToString = (value: JSON | string): string => {
    if (
        typeof value === "string" ||
        (typeof value === "string" && isEmpty(value))
    ) {
        return value || "";
    }
    return JSON.stringify(value);
};

export const providerRecordToColumns = (
    currentProviderName: string | null,
    providersListDataProviders: Record<string, ProviderInfo> | null,
    columns: GridColumns,
    openJsonDialog: () => void,
    setJsonColumn: (value: JSON | string) => void,
    setJsonColumnId: (value: number) => void,
    setJsonColumnName: (value: string) => void
) => {
    if (currentProviderName === null || isEmpty(currentProviderName)) return;
    if (providersListDataProviders === null) return;

    // Extract all the fields for the current provider and filter out the columns that cannot be edited.
    // Note that name is also filtered out solely because we want to display it first before all the other columns
    // and the order in which we define the columns changes the order in which they are rendered.
    const fields = Object.entries(
        providersListDataProviders[currentProviderName].fields
    );
    const requiredFields =
        providersListDataProviders[currentProviderName].requiredFields;
    const preformattedProviderFields = fields.filter(
        ([property]) =>
            property !== "id" &&
            property !== "created_at" &&
            property !== "updated_at" &&
            property !== "name"
    );

    preformattedProviderFields.map(([field, type]) => {
        let newRow: GridEnrichedColDef = {
            field,
        };

        // If the field is in the required fields array then add the following check
        if (requiredFields.includes(field) && type.type !== "json") {
            newRow.preProcessEditCellProps = (
                params: GridPreProcessEditCellProps
            ) => {
                if (type.type === "boolean") {
                    const hasError = typeof params.props.value !== "boolean";
                    return { ...params.props, error: hasError };
                } else {
                    const hasError =
                        params.props.value === undefined ||
                        isEmpty(params.props.value);
                    return { ...params.props, error: hasError };
                }
            };
        }

        if (type.type === "integer") {
            columns.push({
                ...newRow,
                headerName: field.replaceAll("_", " "),
                width: 170,
                type: "number",
                editable: true,
            });
            return null;
        }
        if (type.type === "boolean") {
            columns.push({
                ...newRow,
                headerName: field.replaceAll("_", " "),
                width: 200,
                type: type.type,
                editable: true,
                valueGetter: ({ value }) =>
                    typeof value === "boolean" ? value : false,
            });
            return null;
        }
        if (type.type === "json") {
            columns.push({
                ...newRow,
                headerName: field.replaceAll("_", " "),
                width: 500,
                type: "string",
                editable: true,
                valueGetter: ({ value }) =>
                    value ? jsonToString(value) : null,
                renderEditCell: (params: GridRenderEditCellParams) => (
                    <>
                        <IconButton
                            onClick={() => {
                                openJsonDialog();
                                setJsonColumn(stringToJson(params.value));
                                setJsonColumnId(params.id as number);
                                setJsonColumnName(field);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        {params.value}
                    </>
                ),
            });
            return null;
        }
        if (type?.format === "date-time") {
            columns.push({
                ...newRow,
                headerName: field.replaceAll("_", " "),
                width: 170,
                type: "date",
                editable: true,
                valueGetter: ({ value }) => value && new Date(value),
            });
            return null;
        }
        if (type?.enum) {
            columns.push({
                ...newRow,
                headerName: field.replaceAll("_", " "),
                width: 200,
                type: "singleSelect",
                editable: true,
                valueOptions: type.enum,
                valueGetter: ({ value }) => (value ? value : type.enum?.[0]),
            });
            return null;
        }
        if (field.includes("url")) {
            columns.push({
                ...newRow,
                headerName: field.replaceAll("_", " "),
                width: 250,
                editable: true,
                renderCell: (params: GridRenderCellParams<string>) => (
                    <Link
                        href={`${params.value}`}
                        underline="hover"
                        color="inherit"
                        target="_blank"
                        rel="noopener"
                    >
                        {params.value}
                    </Link>
                ),
            });
            return null;
        }

        columns.push({
            ...newRow,
            headerName: field.replaceAll("_", " "),
            width: 200,
            type: type.type,
            editable: true,
        });
        return null;
    });

    return columns;
};
