import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridRowId } from "@mui/x-data-grid";
import ServiceKeys from "../../../services/serviceKeys";
import * as uiConfigService from '../../../services/uiConfigService';
import { ServerError } from "../../../types/Error";
import { queryClient } from "../../../config/localStorage";
import { UiConfigRename } from "../../../types/UiConfig";


const useUpdateUiConfigRenamesMutation = (apiRef?: React.MutableRefObject<GridApiPro>, isNew?: boolean) => {
    const { enqueueSnackbar } = useSnackbar();
    
    return useMutation({
        mutationFn: async ({ uiConfigRename, deleteItem }: { uiConfigRename: UiConfigRename, deleteItem?: boolean}) => {
            await uiConfigService.updateUiConfigRenames(uiConfigRename, deleteItem); 
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries([ServiceKeys.UiConfigRenames]);
            // Delete the old row in MUI as the ID may not reflect the existing one in the BE
            if (apiRef && isNew) apiRef.current.updateRows([{ id: variables.uiConfigRename.id, _action: 'delete' }]);
            enqueueSnackbar(`Successfully ${variables.deleteItem ? 'deleted' : 'updated'} Ui config rename record with id ${variables.uiConfigRename.id}`, { variant: 'success' });
        },
        onError: (err: ServerError, variables) => {
            // Set the row to edit mode again if it fails to create the provider record
            if (apiRef) apiRef.current.startRowEditMode({ id: variables.uiConfigRename.id as GridRowId });
            enqueueSnackbar(`There was an issue ${variables.deleteItem ? 'deleted' : 'updated'} Ui config rename record with id ${variables.uiConfigRename.id}. ${err.response.data.log}`, { variant: 'error' });
        }
    });
}

export default useUpdateUiConfigRenamesMutation;