import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import ServiceKeys from "../../../services/serviceKeys";
import * as userService from '../../../services/currentUserService';
import { ServerError } from "../../../types/Error";
import { queryClient } from "../../../config/localStorage";
import { UpdateUser } from "../../../types/Users";

const useUpdateCurrentUserMutation = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: async ({ first_name, last_name, email, current_password, password }: UpdateUser ) => {
            await userService.updateUser({ first_name, last_name, email, current_password, password });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([ServiceKeys.User]);
            enqueueSnackbar(`Current user record updated`, { variant: 'success' });
        },
        onError: (err: ServerError) => {
            enqueueSnackbar(`Failed to update current user ${err.response.data.log}`, { variant: 'error' });
        }
    });
}

export default useUpdateCurrentUserMutation;