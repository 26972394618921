import { API } from "../config/api";
import { UpdateUser, User } from "../types/Users";
import { getData, updateData } from "../utils/api";

export async function getUser() {
    const response: User = await getData(API.users.current_user);
    return response;
}

export async function updateUser(payload: UpdateUser) {
    const response: User = await updateData(API.users.current_user, payload);
    return response;
}