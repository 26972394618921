import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import DnDContainer from '../../components/DragAndDrop/DnDContainer';
import { DnDCardItem } from '../../types/DnD';
import { CustomProperty } from '../../types/CustomProperty';
import useGetCustomProperties from '../../hooks/query/customProperties/useGetCustomProperties';
import useUpdateCustomPropertiesMutation from '../../hooks/query/customProperties/useUpdateCustomPropertiesMutation';

const CustomPropertiesIndex: React.FC = () => {
    const theme = useTheme();
    const [hasChanged, setHasChanged] = useState(false);

    const { data: customProperties, error, isLoading } = useGetCustomProperties();
    const { mutate: mutateSaveCustomPropertiesIndex } = useUpdateCustomPropertiesMutation();

    const saveChanges = (items: DnDCardItem[]) => {
        if (Array.isArray(customProperties)) {
            const properties: CustomProperty[] = [];

            items.forEach(item => {
                const foundProperty: CustomProperty | undefined = customProperties.find(property => item.id === property.id);
                if (foundProperty) properties.push(foundProperty);
            });
            mutateSaveCustomPropertiesIndex({ customProperties: properties });
        }

        setHasChanged(false);
    };

    const returnPropertiesOrdered = (properties: CustomProperty[]) => {
        return properties
            .filter(property => property.content_type === 'companies' && property.location === 'custom')
            .sort((a, b) => {
                if (a.index === null) return 1;
                if (b.index === null) return -1;
                
                return a.index - b.index;
            })
            .map((property: CustomProperty) => ({ id: property.id, text: property.name }));
    };

    return (
        <>
            <Typography variant="h1" textTransform="uppercase" gutterBottom>Custom Properties Index</Typography>
            <Typography variant='subtitle1' color={ theme.palette.text.secondary }>
                Drag and drop fields up and down to define order of appearance. Fields will be displayed per the order, as if reading left to right
            </Typography>

            <Box marginTop={ 5 }>
                {Array.isArray(customProperties) && !error && (
                    <DnDContainer
                        header='Page: Company Profile'
                        subHeader='Location: Custom Properties Widget'
                        loading={ isLoading }
                        items={ returnPropertiesOrdered(customProperties) }
                        onMove={ () => setHasChanged(true)}
                        revertChanges={ hasChanged ? () => setHasChanged(false) : undefined }
                        saveChanges={ hasChanged ? (items: DnDCardItem[]) => saveChanges(items) : undefined }
                        cardStyle={{ 
                            background: theme.palette.primary.main,
                            "& .MuiCardContent-root": {
                                color: 'white',
                                padding: '5px', 
                                "&:last-child": { 
                                    paddingBottom: '5px' 
                                }
                            }
                        }}
                    />
                )}
            </Box>
        </>
    );
};

export default CustomPropertiesIndex;