import React from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { extraPalette } from '../../../themes/mui';

interface ButtonProps extends MuiButtonProps {
    black?: boolean;
}

const Button: React.FC<ButtonProps> = React.forwardRef(({ black, children, variant, ...rest }, ref) => {
    if (black && variant === "contained") {
        return (
            <MuiButton
                ref={ref}
                {...rest}
                variant="contained"
                sx={{
                    ...rest.sx,
                    backgroundColor: extraPalette.BLACK2,
                    color: extraPalette.WHITE,
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: extraPalette.GREY7
                    }
                }}
            >
                {children}
            </MuiButton>
        );
    }
    if (black) {
        return (
            <MuiButton
                ref={ref}
                {...rest}
                variant="outlined"
                sx={{
                    ...rest.sx,
                    borderWidth: 2,
                    borderColor: extraPalette.BLACK2,
                    color: extraPalette.BLACK2,
                    borderRadius: 0,
                    '&:hover': {
                        backgroundColor: extraPalette.GREY2,
                        borderWidth: 2,
                        borderColor: extraPalette.BLACK2,
                    }
                }}
                // sx={{
                //     ...rest.sx,
                //     border: extraPalette.BLACK2,
                //     '&:hover': {
                //         backgroundColor: extraPalette.GREY7
                //     }
                // }}
            >
                {children}
            </MuiButton>
        );
    }
    return (
        <MuiButton variant={variant} {...rest}>
            {children}
        </MuiButton>
    );
});

export default Button;