import dayjs from "dayjs";
import { isEmpty } from "ramda";
import { API } from "../config/api";
import { Provider, ProviderDefinitions } from "../types/Providers";
import {
    deleteData,
    formatEndpoint,
    getData,
    postData,
    updateData,
} from "../utils/api";

export async function providersDefinitions() {
    const response: ProviderDefinitions = await getData(API.providers.all);
    return { providers_list: Object.keys(response), providers: response };
}

export async function getProvider(providerName: string) {
    const response: Provider[] = await getData(
        formatEndpoint(API.providers.provider, { tableName: providerName })
    );
    return response;
}

export async function createProvider(providerName: string, provider: Provider) {
    // Need to create a local object to remove the unwanted properties without affecting the object that was passed in
    const providerPayload = { ...provider };
    // Remove properties that shouldn't be sent to the BE
    delete providerPayload.id;
    delete providerPayload.created_at;
    delete providerPayload.updated_at;
    delete providerPayload.isNew;
    delete providerPayload.credits_used_today;
    delete providerPayload.sourcing_due;
    delete providerPayload.sourcing_interval;
    // If a date exists then convert from 2022-03-11T00:00:00.000Z to 2022-03-11
    if (
        providerPayload.last_scraped_at &&
        providerPayload.last_scraped_at instanceof Date
    ) {
        providerPayload.last_scraped_at = dayjs(
            providerPayload.last_scraped_at
        ).format("YYYY-MM-DD");
    }

    const response: Provider = await postData(
        formatEndpoint(API.providers.provider, { tableName: providerName }),
        providerPayload
    );
    return response;
}

export async function updateProvider(providerName: string, provider: Provider) {
    // Need to create a local object to remove the unwanted properties without affecting the object that was passed in
    const providerPayload = { ...provider };
    // Remove properties that shouldn't be sent to the BE
    const providerId = providerPayload.id;
    delete providerPayload.id;
    delete providerPayload.created_at;
    delete providerPayload.updated_at;
    delete providerPayload.isNew;
    delete providerPayload.credits_used_today;
    delete providerPayload.is_out_of_credits;
    delete providerPayload.sourcing_due;
    delete providerPayload.sourcing_interval;
    delete providerPayload.lastChecked;
    // If a date exists then convert from 2022-03-11T00:00:00.000Z to 2022-03-11
    if (
        providerPayload.last_scraped_at &&
        providerPayload.last_scraped_at instanceof Date
    ) {
        providerPayload.last_scraped_at = dayjs(
            providerPayload.last_scraped_at
        ).format("YYYY-MM-DD");
    }

    // Check for empty strings in data and if there are any then set them to null.
    // If it is a string and is not null then check if it's json and if it is then parse the json.
    for (const [key, value] of Object.entries(providerPayload)) {
        if (typeof value === "string" && isEmpty(value)) {
            //@ts-ignore
            providerPayload[key as keyof Provider] = null;
        }
        if (typeof value === "string" && !isEmpty(value)) {
            try {
                providerPayload[key as keyof Provider] = JSON.parse(value);
            } catch (err) {
                continue;
            }
        }
    }

    const response: Provider[] = await updateData(
        formatEndpoint(API.providers.update_provider, {
            tableName: providerName,
            id: providerId,
        }),
        providerPayload
    );
    return response;
}

export async function deleteProvider(providerName: string, providerId: number) {
    const response: any = await deleteData(
        formatEndpoint(API.providers.update_provider, {
            tableName: providerName,
            id: providerId,
        })
    );
    return response;
}
