import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Alert,
    AlertTitle,
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import isStrongPassword from 'validator/lib/isStrongPassword';

import Button from '../../components/UI/Button';
import { ROUTES } from '../../constants';
import { API } from '../../config/api';
import { formatEndpoint, getData, postData } from '../../utils/api';
import AuthPaper from '../../components/AuthPaper';
import { extraTypography } from '../../themes/mui';

const AcceptInvite: React.FC = () => {
    const [password, setPassword] = useState<string | undefined>();
    const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [checkPending, setCheckPending] = useState(false);
    const [checkError, setCheckError] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const navigate = useNavigate();
    const { id, token } = useParams();

    const checkInvite = async () => {
        setCheckPending(true);
        const endpoint = formatEndpoint(API.auth.accept_invite, { id, token });
        try {
            const response = await getData(endpoint);
            setEmail(response.email);
            setFirstName(response.first_name);
            setLastName(response.last_name);
        } catch (err: any) {
            setCheckError(err.message);
        }
        setTimeout(() => {
            setCheckPending(false);
        }, 1000);
    };

    const sendPasswordSet = async () => {
        const payload = { email, first_name: firstName, last_name: lastName, password };
        const endpoint = formatEndpoint(API.auth.accept_invite, { id, token });

        setSubmitted(true);
        try {
            await postData(endpoint, payload);
            setSuccess(true);
        } catch (err) {
            setError(true);
        }
    };

    useEffect(() => {
        // if password is now valid, turn off error
        // conversely, if password is now invalid, turn on error
        if (password && isStrongPassword(password, { minSymbols: 0 }) && passwordError) {
            setPasswordError(false);
        } else if (!password || (!isStrongPassword(password, { minSymbols: 0 }) && !passwordError)) {
            setPasswordError(true);
        }
        if (password && confirmPassword && password !== confirmPassword) {
            setConfirmPasswordError(true);
        }
        if (password && confirmPassword && password === confirmPassword) {
            setPasswordError(false);
            setConfirmPasswordError(false);
            setFormValid(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, confirmPassword]);


    // set background color on mount
    useEffect(() => {
        setPasswordError(false);
        setConfirmPasswordError(false);
        setFormValid(false);
        checkInvite();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!checkPending && checkError) {
        return (
            <AuthPaper>
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Something went wrong</AlertTitle>
                        Unfortunately we were unable to verify your invite. Please contact your system
                        administrator for support.
                    </Alert>
                </Box>
            </AuthPaper>
        );
    }

    if (submitted && error) {
        return (
            <AuthPaper>
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Something went wrong</AlertTitle>
                        Unfortunately we were unable to set your password. Please contact your system
                        administrator for support.
                    </Alert>
                </Box>
            </AuthPaper>
        );
    }

    if (submitted && success) {
        return (
            <AuthPaper>
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Alert variant="filled" severity="success" sx={{ marginTop: '2rem' }}>
                        <AlertTitle>Password successfully set</AlertTitle>
                        Your password has been set, you can now log in using your password
                    </Alert>
                    <Button
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate(ROUTES.LOGIN)}
                        sx={{ marginTop: '4rem', }}
                        black
                    >
                        Back to login
                    </Button>
                </Box>
            </AuthPaper>
        );
    }

    return (
        <AuthPaper>
            {checkPending ? (
                <Box
                    height="100%"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="h6" sx={{ marginBottom: '2rem' }}>Verifying invite...</Typography>
                    <CircularProgress />
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" padding="1rem" width="100%"
                     marginTop="6rem">
                    <Box display="flex" flexDirection="column" alignItems="center" marginBottom="2rem">
                        <Typography sx={{ ...extraTypography.menu1, marginBottom: '.5rem' }}>
                            Set your password
                        </Typography>
                        <Alert variant="filled" severity="info">
                            Your password must be at least 8 characters long, a mixture of both uppercase and
                            lowercase letters and a mixture of letters and numbers
                        </Alert>
                    </Box>
                    <FormControl variant="outlined" sx={{ marginBottom: '1rem', width: '100%' }}>
                        <InputLabel htmlFor="password-input" error={passwordError}>Password</InputLabel>
                        <OutlinedInput
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            onChange={e => setPassword(e.target.value)}
                            onBlur={() => setPasswordError(password === undefined || password === '' || !isStrongPassword(password, { minSymbols: 0 }))}
                            error={passwordError}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        <FormHelperText
                            error={passwordError}
                            id="password-helper-text"
                        >
                            {passwordError ? 'Please enter a valid password' : ' '}
                        </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" sx={{ marginBottom: '1rem', width: '100%' }}>
                        <InputLabel htmlFor="password-confirm-input" error={confirmPasswordError}>Confirm
                            password</InputLabel>
                        <OutlinedInput
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirm-password"
                            onChange={e => setConfirmPassword(e.target.value)}
                            onBlur={() => setConfirmPasswordError(confirmPassword !== password)}
                            error={confirmPasswordError}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                        />
                        <FormHelperText
                            error={confirmPasswordError}
                            id="password-helper-text"
                        >
                            {confirmPasswordError ? 'Passwords do not match' : ' '}
                        </FormHelperText>
                    </FormControl>
                    <Button
                        variant="contained"
                        onClick={() => sendPasswordSet()}
                        disabled={submitted || passwordError || confirmPasswordError || !formValid}
                        sx={{ width: '200px', alignSelf: 'center' }}
                        black
                    >
                        Set password
                    </Button>
                </Box>
            )}
        </AuthPaper>
    );
};

export default AcceptInvite;