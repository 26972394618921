import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import ServiceKeys from "../../../services/serviceKeys";
import * as platformSettingsService from '../../../services/platformSettingsService';
import { ServerError } from "../../../types/Error";
import { queryClient } from "../../../config/localStorage";
import { PlatformSettings } from "../../../types/PlatformSettings";


const useUpdatePlatformSettingsMutation = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: async ({ field, value }: {field: keyof PlatformSettings, value: any}) => await platformSettingsService.updatePlatformSetting(field, value),
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries({ queryKey: [ServiceKeys.PlatformSettings] });
            enqueueSnackbar(`Platform Setting ${variables.field.replaceAll('_', ' ')} updated`, { variant: 'success' });
        },
        onError: (error: ServerError, variables) => {
            enqueueSnackbar(`Platform Setting ${variables.field.replaceAll('_', ' ')} failed to update. Error: ${variables.field === 'company_uniqueness_column' ? error.response.data.stack : error}`, { variant: 'error' });
        }
    });
}

export default useUpdatePlatformSettingsMutation;