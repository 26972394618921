import { useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack";
import ServiceKeys from "../../../services/serviceKeys";
import * as customPropertyService from '../../../services/customPropertiesService';
import { ServerError } from "../../../types/Error";

const useGetCustomProperties = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery({
        queryKey: [ServiceKeys.CustomProperties],
        queryFn: async () => {
            const response = await customPropertyService.getCustomProperties()
            return response.documents;
        },
        onError: (err: ServerError) => {
            enqueueSnackbar(`An error occurred while fetching custom properties ${err.response.data.log}`, { variant: 'error' });
        }
    });
}

export default useGetCustomProperties;