import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UiConfigFormGroup from "../../components/UI/UiConfig/UiConfigFormGroups";
import { UI_CONFIG_MAPPING } from "../../constants/uiConfig";
import { UiConfigPage } from "../../types/UiConfig";
import { formatData } from "../../utils/uiConfig";
import UiConfigGrid from "../../components/UI/UiConfig/UiConfigGrid";
import UiConfigRenames from "../../components/UI/UiConfig/UiConfigRenames";
import useGetUiConfig from "../../hooks/query/uiConfig/useGetUiConfig";
import useGetUiConfigRenames from "../../hooks/query/uiConfig/useGetUiConfigRenames";
import useGetCustomProperties from "../../hooks/query/customProperties/useGetCustomProperties";

const UiConfig: React.FC = () => {
    const { data: uiConfigData } = useGetUiConfig();
    const { data: uiConfigRenames, isLoading: uiConfigRenamesPending } =
        useGetUiConfigRenames();
    const { data: customPropertiesData } = useGetCustomProperties();

    const [uiConfigFormattedPageData, setUiConfigFormattedPageData] = useState<
        UiConfigPage[] | undefined | null
    >(null);
    const [uiConfigFormattedFeaturesData, setUiConfigFormattedFeaturesData] =
        useState<UiConfigPage[] | undefined | null>(null);
    const [activePage, setActivePage] = useState<UiConfigPage | null>(null);

    useEffect(() => {
        if (!uiConfigFormattedPageData)
            setUiConfigFormattedPageData(
                formatData(
                    uiConfigData,
                    UI_CONFIG_MAPPING.PAGES,
                    customPropertiesData
                )
            );
        if (!uiConfigFormattedFeaturesData)
            setUiConfigFormattedFeaturesData(
                formatData(uiConfigData, UI_CONFIG_MAPPING.FEATURES)
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiConfigData, customPropertiesData]);

    if (!activePage) {
        return (
            <>
                <Typography
                    variant="h1"
                    component="div"
                    textTransform="uppercase"
                    gutterBottom
                >
                    UI Configuration
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="div">
                    Enable or disable features and sections in the platform for
                    all users, and modify some of their names
                </Typography>
                <UiConfigFormGroup
                    title="Global Features"
                    subheading="Features appear within pages across the platform. These controls override any related selection made in the Pages section below."
                    data={uiConfigFormattedFeaturesData}
                />
                <UiConfigFormGroup
                    title="Pages and Elements"
                    subheading="Controls the activation of the main pages and subpages and gives you access to the configuration of elements within them, if available."
                    data={uiConfigFormattedPageData}
                    onClick={(page) => setActivePage(page)}
                />
                <UiConfigFormGroup
                    title="Edit Element Names"
                    subheading="Access this section to rename certain elements within the platform."
                />
                <UiConfigRenames
                    data={uiConfigRenames}
                    pending={uiConfigRenamesPending}
                />
            </>
        );
    }
    return (
        <>
            <Breadcrumbs sx={{ marginBottom: "20px" }}>
                <Link
                    underline="hover"
                    color="inherit"
                    component="button"
                    variant="body1"
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={() => setActivePage(null)}
                >
                    <ArrowBackIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    pages
                </Link>
            </Breadcrumbs>
            <Typography
                variant="h1"
                component="div"
                textTransform="uppercase"
                gutterBottom
            >
                Pages and Elements
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
                Controls the activation of elements within a selected page or
                subpage
            </Typography>
            <UiConfigGrid data={activePage} />
        </>
    );
};

export default UiConfig;
