export type UiConfigPage = {
    id?: number;
    name: string;
    enabled?: number | boolean; //boolean
    created_at?: Date;
    updated_at?: Date;
    tabs?: UiConfigTab[];
    displayName: string;
    description?: string;
    default?: number | boolean; //boolean
    image?: string;
};

export type UiConfigTab = {
    id?: number;
    ui_configuration_page_id?: number;
    name: string;
    enabled?: number | boolean; //boolean
    created_at?: Date;
    updated_at?: Date;
    widgets?: UiConfigWidget[];
    displayName?: string;
    description?: string;
    default?: number | boolean; //boolean
    image?: string;
};

export type UiConfigWidget = {
    id?: number;
    ui_configuration_tab_id?: number;
    name: string;
    enabled?: number | boolean; //boolean
    created_at?: Date;
    updated_at?: Date;
    fields?: UiConfigField[];
    displayName?: string;
    description?: string;
    default?: number | boolean; //boolean
    image?: string;
};

export type UiConfigField = {
    id?: number;
    ui_configuation_widget_id?: number;
    name: string;
    enabled?: number | boolean; //boolean
    created_at?: Date;
    updated_at?: Date;
    displayName?: string;
    description?: string;
    default?: number | boolean; //boolean
    image?: string;
};

export type UiConfigRename = {
    id: string | number;
    identifier: string;
    singularName: string;
    pluralName: string;
    created_at?: Date;
    updated_at?: Date;
    isNew?: number | boolean; //boolean
};

export enum UI_CONFIG_TYPE {
    PAGES = "pages",
    TABS = "tabs",
    WIDGETS = "widgets",
    FIELDS = "fields",
}

export type UpdateUiConfig = {
    type: UI_CONFIG_TYPE;
    data: UiConfigPage | UiConfigTab | UiConfigWidget | UiConfigField;
    enabled: boolean;
    activePage?: UiConfigPage | null;
    activeTab?: UiConfigTab | null;
    activeWidget?: UiConfigWidget | null;
};

export type UiConfigConstant = {
    FEATURES: {
        name: string;
        displayName: string;
        default?: boolean;
    }[];
    PAGES: {
        name: string;
        displayName: string;
        default?: boolean;
        tabs?: {
            name: string;
            displayName: string;
            default?: boolean;
            widgets?: {
                name: string;
                displayName: string;
                default?: boolean;
                fields?: {
                    name: string;
                    displayName: string;
                    default?: boolean;
                }[];
            }[];
        }[];
    }[];
};
