import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';

interface UiConfigButtonProps extends ButtonProps {
    /**
     * Turn on or off the end icon
     */
    disableEndIcon?: boolean
}

const UiConfigButton: React.FC<UiConfigButtonProps> = React.forwardRef(({ disableEndIcon, children, ...rest }, ref) => {
    return (
        <Button
            ref={ref}
            variant="text"
            endIcon={!disableEndIcon ? <ArrowForwardIcon /> : null}
            size="small"
            color="info"
            {...rest}
            sx={{
                ...rest.sx,
                color: "text.primary",
                borderStyle: "none",
                borderColor: "text.primary",
                background: "background.default",
                boxShadow: "0px 3px 4px -1px rgba(186, 186, 186, 0.25)",
                borderRadius: "4px",
                paddingX: "10px",
                textTransform: "none",
                minWidth: "200px",
                justifyContent: "left",
                "& .MuiButton-endIcon": {
                    marginLeft: "auto",
                    "& svg": {
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#969696"
                    }
                }
            }}
        >
            {children}
        </Button>
    );
});

export default UiConfigButton;
