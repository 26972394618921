import React from 'react';
import { BrowserRouter, Navigate, Route, Routes as SwitchRoutes, useLocation } from 'react-router-dom';

import { ROUTES } from '../../constants';
import Login from '../Login';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import AcceptInvite from '../AcceptInvite';
import PageWrapper from '../PageWrapper';
import Dashboard from '../Dashboard';
import usePrevious from '../../hooks/usePrevious';
import UserAccount from '../UserAccount';
import UiConfig from '../UiConfig';
import CustomPropertiesIndex from '../CustomPropertiesIndex/CustomPropertiesIndex';
import Providers from '../Providers/Providers';
import PlatformSettings from '../PlatformSettings';
import ServiceKeys from '../../services/serviceKeys';
import { loadAuthState, queryClient } from '../../config/localStorage';
import { AuthLogin } from '../../types/Auth';
import axios from '../../config/axios';

const Routes: React.FC = () => {

    const setAxiosAuthHeaders = (authToken: string | undefined) => {
        axios.defaults.headers.common['Authorization'] = `bearer ${authToken || ''}`;
        axios.defaults.headers.get['Authorization'] = `bearer ${authToken || ''}`;
    }

    const RequireAuth = ({ children }: { children: JSX.Element }) => {
        const auth = queryClient.getQueryData<AuthLogin>([ServiceKeys.Auth]);
        const authToken = auth?.token;
        const prevAuthToken = usePrevious(authToken);
        setAxiosAuthHeaders(authToken);
        const location = useLocation();

        // If no auth token is found in the queryClient then attempt to load and set it
        // using the stored auth token in local storage
        if (!authToken) {
            const localAuth = loadAuthState();
            if (localAuth) {
                queryClient.setQueryData([ServiceKeys.Auth], localAuth);
                setAxiosAuthHeaders(localAuth.token);
                return children;
            }
        }

        if (!authToken) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.

            // except, if the user has logged out then just land them on the dashboard (i.e. don't deep link them to
            // where they wanted to go before forcing them to login)
            if (prevAuthToken) {
                return <Navigate to={ROUTES.LOGIN} />;
            }
            return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
        }

        return children;
    };

    return (
        <BrowserRouter>
            <SwitchRoutes>
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={ROUTES.ACCEPT_INVITE} element={<AcceptInvite />} />
                <Route path="/" element={<PageWrapper />}>
                    <Route index element={<RequireAuth><Navigate to={ROUTES.DASHBOARD} /></RequireAuth>} />
                    <Route path={ROUTES.PROVIDERS} element={<RequireAuth><Providers /></RequireAuth>} />
                    <Route path={ROUTES.PLATFORM_SETTINGS} element={<RequireAuth><PlatformSettings /></RequireAuth>} />
                    <Route path={ROUTES.DASHBOARD} element={<RequireAuth><Dashboard /></RequireAuth>} />
                    <Route
                        path={ROUTES.UI_CONFIGURATION}
                        element={<RequireAuth><UiConfig /></RequireAuth>}
                    />
                    <Route
                        path={ROUTES.CUSTOM_PROPERTIES_INDEX}
                        element={<RequireAuth><CustomPropertiesIndex /></RequireAuth>}
                    />
                    <Route
                        path={ROUTES.BRANDING}
                        element={<RequireAuth><UiConfig /></RequireAuth>}
                    />
                    <Route
                        path={ROUTES.SECTORS}
                        element={<RequireAuth><UiConfig /></RequireAuth>}
                    />
                    <Route
                        path={ROUTES.SIGNALS}
                        element={<RequireAuth><UiConfig /></RequireAuth>}
                    />
                    <Route path={ROUTES.MY_ACCOUNT} element={<RequireAuth><UserAccount /></RequireAuth>} />
                </Route>
                <Route path="*" element={<Navigate to={ROUTES.DASHBOARD} />} />
            </SwitchRoutes>
        </BrowserRouter>
    );
};

export default Routes;