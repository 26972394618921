import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import ServiceKeys from "../../../services/serviceKeys";
import * as providersService from '../../../services/providersService';
import { ServerError } from "../../../types/Error";

const useGetProviderData = (currentProviderName: string) => {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery({
        queryKey: [ServiceKeys.Providers, currentProviderName],
        queryFn: async () => await providersService.getProvider(currentProviderName),
        onError: (err: ServerError) => {
            enqueueSnackbar(`Failed to fetch provider info for ${currentProviderName}. ${err.response.data.log}`, { variant: 'error' });
        }
    });
}

export default useGetProviderData;