import { useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack";
import ServiceKeys from "../../../services/serviceKeys";
import * as uiConfigService from '../../../services/uiConfigService';
import { ServerError } from "../../../types/Error";

const useGetUiConfig = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery({
        queryKey: [ServiceKeys.UiConfig],
        queryFn: uiConfigService.getUiConfig,
        onError: (err: ServerError) => {
            enqueueSnackbar(`An error occurred while fetching all users data ${err.response.data.log}`, { variant: 'error' })
        },
        retry: false
    })
}

export default useGetUiConfig;