import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Switch,
    Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import { HAS_TOGGLE } from "../../../constants/uiConfig";
import { UiConfigPage, UI_CONFIG_TYPE } from "../../../types/UiConfig";
import usePrevious from "../../../hooks/usePrevious";
import useUpdateUiConfigMutation from "../../../hooks/query/uiConfig/useUpdateUiConfigMutation";

interface UiConfigFormGroupProps {
    title: string;
    subheading?: string;
    data?: UiConfigPage[] | null | undefined;
    onClick?: (data: any) => void;
}

const UiConfigFormGroup: React.FC<UiConfigFormGroupProps> = ({
    title,
    subheading,
    data,
    onClick,
}) => {
    const { mutate: updateUiConfig } = useUpdateUiConfigMutation();
    const [configData, setConfigData] = useState(data ? [...data] : []);

    const prevData = usePrevious(data);

    useEffect(() => {
        if (data && !prevData) {
            setConfigData(data ? [...data] : []);
        }
    }, [data, prevData]);

    const handleSwitchChange = (
        item: any,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const index = configData.findIndex((e) => e.name === item.name);
        const newData = [...configData];
        if (newData?.[index]) newData[index].enabled = event.target.checked;
        setConfigData(newData);
        updateUiConfig({
            type: UI_CONFIG_TYPE.PAGES,
            data: item,
            enabled: event.target.checked,
        });
    };

    const renderFormControlLabels = () => {
        return configData.map((item) => {
            let label;
            if (item.tabs) {
                label = (
                    <Button
                        variant="text"
                        endIcon={<ArrowForwardIcon />}
                        size="small"
                        color="info"
                        onClick={onClick ? () => onClick(item) : undefined}
                        sx={{
                            color: "text.primary",
                            borderStyle: "none",
                            borderColor: "text.primary",
                            background: "background.default",
                            boxShadow:
                                "0px 3px 4px -1px rgba(186, 186, 186, 0.25)",
                            borderRadius: "4px",
                        }}
                    >
                        {item.displayName}
                    </Button>
                );
            } else {
                label = item.displayName;
            }

            return (
                <FormControlLabel
                    key={item.name}
                    value={item.name}
                    label={label}
                    labelPlacement="end"
                    control={
                        <Switch
                            color="success"
                            checked={
                                item.enabled !== undefined
                                    ? !!item.enabled
                                    : !!item.default
                            }
                            disabled={!HAS_TOGGLE.includes(item.name)}
                            onChange={(e) => handleSwitchChange(item, e)}
                        />
                    }
                />
            );
        });
    };

    const renderedFormControlLabels = renderFormControlLabels();

    return (
        <Box marginTop="40px">
            <Typography variant="h6" gutterBottom component="div">
                {title}
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
                {subheading}
            </Typography>
            {renderedFormControlLabels && (
                <FormGroup
                    sx={{
                        display: "grid",
                        gridTemplateColumns:
                            "repeat(auto-fill, minmax(375px, 1fr))",
                    }}
                >
                    {renderedFormControlLabels}
                </FormGroup>
            )}
        </Box>
    );
};

export default UiConfigFormGroup;
