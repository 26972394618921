import { API } from "../config/api";
import { PlatformSettings } from "../types/PlatformSettings";
import { getData, updateData } from "../utils/api";

export async function getAllPlatformSettings() {
    const response: PlatformSettings = await getData(API.platform_settings.all);
    return response;
}

export async function updatePlatformSetting(field: keyof PlatformSettings, value: any) {
    return await updateData(API.platform_settings.all, { [field]: value })
}