import { API } from "../config/api";
import { queryClient, saveAuthState } from "../config/localStorage";
import { AuthLogin } from "../types/Auth";
import { Role } from "../types/Users";
import { getData, postData } from "../utils/api";

export async function getAllRoles() {
    const response: Role = await getData(API.auth.roles);
    return response;
}

export async function login(email: string, password: string) {
    const response: AuthLogin = await postData(API.auth.login, { email, password });
    return response;
}

export async function logout() {
    queryClient.clear();
    saveAuthState(undefined);
}
