import React from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import axios from "./config/axios";
import Routes from "./pages/Routes";
import { queryClient } from "./config/localStorage";
import { mui_theme } from "./themes/mui";
import SnackbarProvider from "./utils/snackbar";
import { logout } from "./services/authService";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
    "b4f8e6ab4da39ff45c518b359407dc79Tz05MDEwOCxFPTE3NDY5NzMwNTYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (
            error.response &&
            error.response.status === 401 &&
            error.response.data.log !==
                "Current password does not match stored password"
        ) {
            logout();
        }

        return Promise.reject(error);
    }
);

const App = () => {
    const theme = createTheme(mui_theme);
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider>
                    <ReactQueryDevtools initialIsOpen position="bottom-right" />
                    <Routes />
                </SnackbarProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;
