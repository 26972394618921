import { API } from "../config/api";
import { PagedRequest } from "../types/API";
import { CustomProperty } from "../types/CustomProperty";
import { formatEndpoint, getData, updateData } from "../utils/api";

export async function getCustomProperties() {
    const response: PagedRequest<CustomProperty> = await getData(API.custom_properties.definitions, { pageSize: 1000 });
    return response;
}

export async function savePropertiesIndex(customProperties: CustomProperty[]) {
    const promises = customProperties.map((property, index) => {
        return updateData(formatEndpoint(API.custom_properties.admin_definition, { definitionId: property.id }), { index });
    });
    const response: PromiseSettledResult<CustomProperty>[] = await Promise.allSettled(promises);
    return response;
}