import { API } from "../config/api";
import { deleteData, formatEndpoint, getData, postData } from "../utils/api";
import {
    UiConfigField,
    UiConfigPage,
    UiConfigRename,
    UiConfigTab,
    UiConfigWidget,
    UI_CONFIG_TYPE,
} from "../types/UiConfig";

export async function getUiConfig() {
    const response: UiConfigPage[] = await getData(API.ui_config.pages);
    return response;
}

export async function updateUiConfig(
    type: UI_CONFIG_TYPE,
    data: UiConfigPage | UiConfigTab | UiConfigWidget | UiConfigField,
    enabled: boolean,
    activePage?: UiConfigPage | null,
    activeTab?: UiConfigTab | null,
    activeWidget?: UiConfigWidget | null
) {
    const body: any = {};

    if (type === UI_CONFIG_TYPE.PAGES) {
        body.name = data.name;
        body.enabled = enabled;

        await postData(API.ui_config.pages, body);
    }

    if (type === UI_CONFIG_TYPE.TABS) {
        const pageBody = {
            name: activePage?.name,
        };

        const page: UiConfigPage = await postData(
            API.ui_config.pages,
            pageBody
        );

        body.name = data.name;
        body.enabled = enabled;
        body.ui_configuration_page_id = page.id;

        await postData(API.ui_config.tabs, body);
    }

    if (type === UI_CONFIG_TYPE.WIDGETS) {
        const pageBody = {
            name: activePage?.name,
        };

        const page: UiConfigPage = await postData(
            API.ui_config.pages,
            pageBody
        );

        const tabBody = {
            name: activeTab?.name,
            ui_configuration_page_id: page.id,
        };

        const tab: UiConfigTab = await postData(API.ui_config.tabs, tabBody);

        body.name = data.name;
        body.enabled = enabled;
        body.ui_configuration_tab_id = tab.id;

        await postData(API.ui_config.widgets, body);
    }

    if (type === UI_CONFIG_TYPE.FIELDS) {
        const pageBody = {
            name: activePage?.name,
        };

        const page: UiConfigPage = await postData(
            API.ui_config.pages,
            pageBody
        );

        const tabBody = {
            name: activeTab?.name,
            ui_configuration_page_id: page.id,
        };

        const tab: UiConfigTab = await postData(API.ui_config.tabs, tabBody);

        const widgetBody = {
            name: activeWidget?.name,
            ui_configuration_tab_id: tab.id,
        };

        const widget: UiConfigWidget = await postData(
            API.ui_config.widgets,
            widgetBody
        );

        body.name = data.name;
        body.enabled = enabled;
        body.ui_configuration_widget_id = widget.id;

        await postData(API.ui_config.fields, body);
    }
    return;
}

export async function getUiConfigRenames() {
    const response: UiConfigRename[] = await getData(API.ui_config.renames);
    return response;
}

export async function updateUiConfigRenames(
    uiConfigRename: UiConfigRename,
    deleteItem?: boolean
) {
    const renamePayload: any = { ...uiConfigRename };
    delete renamePayload.created_at;
    delete renamePayload.updated_at;
    delete renamePayload.id;
    delete renamePayload.isNew;

    if (deleteItem) {
        return await deleteData(
            formatEndpoint(API.ui_config.deleteRename, {
                id: uiConfigRename.id,
            })
        );
    } else {
        return await postData(API.ui_config.renames, [renamePayload]);
    }
}
