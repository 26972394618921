import React, { useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

interface PlatformSettingsRadioButtonsProps {
    heading?: string,
    options: { value: unknown, label: string }[],
    value: string,
    disabled?: boolean,
    onChange: (value: string) => void
}

const PlatformSettingsRadioButtons: React.FC<PlatformSettingsRadioButtonsProps> = ({ heading, options, value, disabled, onChange }) => {

    const [radioButton, setRadioButton] = useState(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setRadioButton(value);
        onChange(value);
    };

    return (
        <FormControl disabled={disabled}>
            {heading ??
                <FormLabel id="controlled-radio-buttons-group">{heading}</FormLabel>
            }
            <RadioGroup
                aria-labelledby="controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioButton}
                onChange={handleChange}
            >
                {options.map((option, index) => (
                    <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default PlatformSettingsRadioButtons;
