import React, { useEffect, useState } from "react";
import { Grid, Stack, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import UiConfigButton from "../Button/UiConfigButton";
import {
    UiConfigField,
    UiConfigTab,
    UiConfigWidget,
    UI_CONFIG_TYPE,
} from "../../../types/UiConfig";
import { HAS_TOGGLE } from "../../../constants/uiConfig";
import usePrevious from "../../../hooks/usePrevious";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

interface UiConfigGridItemsProps {
    data: UiConfigTab[] | UiConfigWidget[] | UiConfigField[] | undefined;
    type: UI_CONFIG_TYPE;
    onButtonClick?: (data: any) => void;
    onSwitchChange?: (data: any) => void;
}

const UiConfigGridItems: React.FC<UiConfigGridItemsProps> = ({
    data,
    type,
    onButtonClick,
    onSwitchChange,
}) => {
    const [configData, setConfigData] = useState(data ? [...data] : []);

    const handleSwitch = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newData = [...configData];
        if (newData?.[index]) newData[index].enabled = event.target.checked;
        setConfigData(newData);
        if (onSwitchChange) onSwitchChange(newData[index]);
    };

    const handleButton = (item: any) => {
        if (onButtonClick) onButtonClick(item);
    };

    const prevData = usePrevious(data);

    useEffect(() => {
        if (data !== prevData) {
            setConfigData(data ? [...data] : []);
        }
    }, [data, prevData]);

    const renderGridItems = () => {
        if (configData.length === 0) {
            return (
                <Grid sx={{ display: "flex" }} item xs={8}>
                    <Item sx={{ padding: "7px" }}>No {type} available</Item>
                </Grid>
            );
        }

        return configData.map((item, index) => {
            const isFieldType = type === UI_CONFIG_TYPE.FIELDS;
            const customPropertiesRegex =
                /(companies_table_custom_properties)\w+/g;
            return (
                <Grid item xs={8} key={index}>
                    <Item>
                        <Stack justifyItems="space-between" direction="row">
                            {(HAS_TOGGLE.includes(item.name) ||
                                customPropertiesRegex.test(item.name)) && (
                                <Switch
                                    color="success"
                                    onChange={(e) => handleSwitch(index, e)}
                                    checked={
                                        item.enabled !== undefined
                                            ? !!item.enabled
                                            : !!item.default
                                    }
                                />
                            )}
                            <UiConfigButton
                                disableEndIcon={isFieldType}
                                sx={{ flexGrow: 1 }}
                                onClick={(_e) => handleButton(item)}
                            >
                                {item.displayName
                                    ? item.displayName
                                    : item.name}
                            </UiConfigButton>
                        </Stack>
                    </Item>
                </Grid>
            );
        });
    };

    return <React.Fragment>{renderGridItems()}</React.Fragment>;
};

export default UiConfigGridItems;
