import { CustomProperty } from "../types/CustomProperty";
import { UiConfigPage } from "../types/UiConfig";

/**
 * - previous stop: Sanity Island
 * - current stop: Insanity Island
 * 1. map over the constant UI_CONFIG_MAPPING stored here.
 * 2. map over the incoming uiConfig.
 * 3. loop over each item in the uiConfig until we find one that matches the first item in UI_CONFIG_MAPPING.
 * 4. pass all the uiConfig data into the UI_CONFIG_MAPPING object at that position.
 * 5. pipe in the other nested field back in. e.g. when on page pass in tabs, when on tab pass in widgets, etc.
 * 6. repeat the process for all the tabs, widgets and fields.
 * 7. once the page is complete start again on the second page in UI_CONFIG_MAPPING.
 * 8. repeat until the heat death of the universe (or until everything is mapped).
 * @param uiConfig Current UI Configuration
 * @param uiConfigMapping UI Configuration mapping
 * @returns mapped UI Configuration
 */
export const formatData = (
    uiConfig: UiConfigPage[] | undefined | null,
    uiConfigMapping: UiConfigPage[],
    customProperties?: CustomProperty[] | null
) => {
    if (!uiConfig) return;
    uiConfigMapping.forEach((pageMap, pageMapIndex) => {
        uiConfig.forEach((pageData, pageDataIndex) => {
            if (pageMap.name === pageData.name) {
                uiConfigMapping[pageMapIndex] = {
                    ...pageMap,
                    ...pageData,
                    tabs: pageMap.tabs,
                };
                const mapPage = uiConfigMapping[pageMapIndex];
                const dataPage = uiConfig[pageDataIndex];

                mapPage?.tabs?.forEach((tabMap, tabMapIndex) => {
                    dataPage?.tabs?.forEach((tabData, tabDataIndex) => {
                        if (tabMap.name === tabData.name) {
                            if (mapPage.tabs)
                                mapPage.tabs[tabMapIndex] = {
                                    ...tabMap,
                                    ...tabData,
                                    widgets: tabMap.widgets,
                                };
                            const mapTab = mapPage.tabs?.[tabMapIndex];
                            const dataTab = dataPage.tabs?.[tabDataIndex];

                            mapTab?.widgets?.forEach(
                                (widgetMap, widgetMapIndex) => {
                                    dataTab?.widgets?.forEach(
                                        (widgetData, widgetDataIndex) => {
                                            if (
                                                widgetMap.name ===
                                                widgetData.name
                                            ) {
                                                if (mapTab.widgets)
                                                    mapTab.widgets[
                                                        widgetMapIndex
                                                    ] = {
                                                        ...widgetMap,
                                                        ...widgetData,
                                                        fields: widgetMap.fields,
                                                    };
                                                const mapWidget =
                                                    mapTab.widgets?.[
                                                        widgetMapIndex
                                                    ];
                                                const dataWidget =
                                                    dataTab.widgets?.[
                                                        widgetDataIndex
                                                    ];

                                                mapWidget?.fields?.forEach(
                                                    (
                                                        fieldMap,
                                                        fieldMapIndex
                                                    ) => {
                                                        dataWidget?.fields?.forEach(
                                                            (fieldData) => {
                                                                if (
                                                                    fieldMap.name ===
                                                                    fieldData.name
                                                                ) {
                                                                    if (
                                                                        mapWidget.fields
                                                                    )
                                                                        mapWidget.fields[
                                                                            fieldMapIndex
                                                                        ] = {
                                                                            ...fieldMap,
                                                                            ...fieldData,
                                                                        };
                                                                }
                                                                // This should add on the custom properties that currently exist in the database
                                                                else if (
                                                                    !mapWidget?.fields?.find(
                                                                        (
                                                                            field
                                                                        ) =>
                                                                            field.name ===
                                                                            fieldData.name
                                                                    )
                                                                ) {
                                                                    const customProperty =
                                                                        customProperties?.find(
                                                                            (
                                                                                prop
                                                                            ) =>
                                                                                prop.id ===
                                                                                Number(
                                                                                    fieldData.name.replace(
                                                                                        "companies_table_custom_properties_",
                                                                                        ""
                                                                                    )
                                                                                )
                                                                        );
                                                                    if (
                                                                        customProperty
                                                                    ) {
                                                                        mapWidget.fields?.push(
                                                                            {
                                                                                ...fieldData,
                                                                                displayName:
                                                                                    customProperty?.name,
                                                                            }
                                                                        );
                                                                        // Remove the element from the array so that we can add the rest later.
                                                                        const customPropertyIndex =
                                                                            customProperties?.indexOf(
                                                                                customProperty,
                                                                                0
                                                                            );
                                                                        if (
                                                                            customPropertyIndex &&
                                                                            customPropertyIndex >
                                                                                -1
                                                                        )
                                                                            customProperties?.splice(
                                                                                customPropertyIndex,
                                                                                1
                                                                            );
                                                                    }
                                                                }
                                                            }
                                                        );
                                                    }
                                                );
                                            }
                                        }
                                    );
                                }
                            );
                        }
                    });
                });
            }
        });
    });

    // Add the remaining custom properties to the uiConfigMapping in the companies table
    if (customProperties) {
        const companiesTableFields = uiConfigMapping
            .find((page) => page.name === "companies")
            ?.tabs?.find((tab) => tab.name === "companies_table_locked")
            ?.widgets?.find(
                (widget) => widget.name === "companies_table_locked"
            );
        customProperties.forEach((prop) => {
            companiesTableFields?.fields?.push({
                name: `companies_table_custom_properties_${prop.id}`,
                displayName: prop.name,
                ui_configuation_widget_id: companiesTableFields.id,
                default: true,
            });
        });
    }
    return uiConfigMapping;
};
