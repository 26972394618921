import React, { useEffect, useState } from 'react';
import {
    Box, Card, CardContent,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import isEmail from 'validator/lib/isEmail';
import isStrongPassword from 'validator/lib/isStrongPassword';

import Button from '../../components/UI/Button';
import { extraPalette, extraShadows } from '../../themes/mui';
import useGetCurrentUser from '../../hooks/query/currentUser/useGetCurrentUser';
import useUpdateCurrentUserMutation from '../../hooks/query/currentUser/useUpdateCurrentUserMutation';

const UserAccount: React.FC = () => {
    const { data: currentUser } = useGetCurrentUser();
    const { mutate: updateCurrentUser } = useUpdateCurrentUserMutation();

    // form fields
    const [firstName, setFirstName] = useState(currentUser?.first_name || '');
    const [lastName, setLastName] = useState(currentUser?.last_name || '');
    const [email, setEmail] = useState(currentUser?.email || '');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [originalData, setOriginalData] = useState({
        firstName: firstName,
        lastName: lastName,
        email: email
    });

    // controls
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [anyRecordChanged, setAnyRecordChanged] = useState(false);

    // validations
    const [anyError, setAnyError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const saveChanges = () => {
        setEditMode(false);
        const payload = {
            first_name: firstName,
            last_name: lastName,
            email,
            current_password: currentPassword || undefined,
            password: newPassword || undefined,
        };
        updateCurrentUser(payload);
    };

    useEffect(() => {
        if (editMode) {
            setAnyRecordChanged(
                firstName !== originalData.firstName ||
                lastName !== originalData.lastName ||
                email !== originalData.email ||
                currentPassword !== '' ||
                newPassword !== '' ||
                confirmPassword !== ''
            );
            setFirstNameError(!firstName);
            setLastNameError(!lastName);
            setEmailError(!isEmail(email));
            setCurrentPasswordError(currentPassword !== '' && !isStrongPassword(currentPassword, { minSymbols: 0 }));
            setNewPasswordError(newPassword !== '' && !isStrongPassword(newPassword, { minSymbols: 0 }));
            setConfirmPasswordError(newPassword !== confirmPassword);
        }
    }, [firstName, lastName, email, currentPassword, newPassword, confirmPassword, editMode, originalData]);

    useEffect(() => {
        setAnyError(firstNameError || lastNameError || emailError || currentPasswordError || newPasswordError || confirmPasswordError);
    }, [firstNameError, lastNameError, emailError, currentPasswordError, newPasswordError, confirmPasswordError]);

    useEffect(() => {
        if (currentUser) {
            setFirstName(currentUser.first_name);
            setLastName(currentUser.last_name);
            setEmail(currentUser.email);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setOriginalData({
                firstName: currentUser.first_name,
                lastName: currentUser.last_name,
                email: currentUser.email,
            });
        }
    }, [currentUser]);

    return (
        <>
            <Typography variant="h1" textTransform="uppercase">My account</Typography>
            <Box display="flex" flexDirection="column">
                <Box display="flex" justifyContent="flex-end" marginTop="1rem" gap="1rem">
                    <Button
                        startIcon={editMode ? <CancelIcon /> : <EditIcon />}
                        onClick={() => {
                            if (editMode) {
                                // revert data
                                setFirstName(originalData.firstName);
                                setLastName(originalData.lastName);
                                setEmail(originalData.email);
                                setCurrentPassword('');
                                setNewPassword('');
                                setConfirmPassword('');
                                setEditMode(false);
                                setCurrentPasswordError(false);
                                setNewPasswordError(false);
                                setConfirmPasswordError(false);
                                return;
                            }
                            setEditMode(true);
                        }}
                        black
                        variant="outlined"
                    >
                        {editMode ? 'Cancel' : 'Edit'}
                    </Button>
                    <Button
                        black
                        variant="contained"
                        startIcon={<SaveIcon />}
                        disabled={!editMode || anyError || !anyRecordChanged}
                        onClick={saveChanges}
                    >
                        Save
                    </Button>
                </Box>
                <Card sx={{ width: '518px', boxShadow: extraShadows.SHADOW2, border: `1px solid ${extraPalette.GREY3}` }}>
                    <CardContent sx={{ padding: '19px 26px' }}>
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h3" textTransform='uppercase' sx={{ marginBottom: '2rem' }}>User information</Typography>
                            <TextField
                                type="text"
                                id="first-name"
                                label="First name"
                                variant="outlined"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                disabled={!editMode}
                                error={firstNameError}
                                required
                                sx={{ marginBottom: '2rem' }}
                            />
                            <TextField
                                type="text"
                                id="last-name"
                                label="Last name"
                                variant="outlined"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                disabled={!editMode}
                                error={lastNameError}
                                required
                                sx={{ marginBottom: '2rem' }}
                            />
                            <TextField
                                type="email"
                                id="email"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                disabled={!editMode}
                                error={emailError}
                                required
                                sx={{ marginBottom: '2rem' }}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" marginTop="2rem">
                            <Typography variant="h3" textTransform='uppercase' sx={{ marginBottom: '2rem' }}>Set new password</Typography>
                            <FormControl
                                variant="outlined"
                                disabled={!editMode}
                                sx={{ marginBottom: '1rem' }}
                            >
                                <InputLabel htmlFor="current-password-input" error={currentPasswordError}>Current
                                    password</InputLabel>
                                <OutlinedInput
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    id="current-password"
                                    autoComplete="new-password"
                                    error={currentPasswordError}
                                    onChange={e => setCurrentPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                            >
                                                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Current password"
                                />
                                <FormHelperText
                                    error={currentPasswordError}
                                    id="password-helper-text"
                                >
                                    {currentPasswordError ? 'Please enter a valid password' : ' '}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                disabled={!editMode}
                                sx={{ marginBottom: '1rem' }}
                            >
                                <InputLabel htmlFor="new-password-input" error={newPasswordError}>New password</InputLabel>
                                <OutlinedInput
                                    type={showNewPassword ? 'text' : 'password'}
                                    id="new-password"
                                    autoComplete="new-password"
                                    error={newPasswordError}
                                    onChange={e => setNewPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="New password"
                                />
                                <FormHelperText
                                    error={newPasswordError}
                                    id="password-helper-text"
                                >
                                    {newPasswordError ? 'Please enter a valid password' : ' '}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                sx={{ marginBottom: '1rem' }}
                                disabled={!editMode}
                            >
                                <InputLabel htmlFor="confirm-password-input" error={confirmPasswordError}>Confirm
                                    password</InputLabel>
                                <OutlinedInput
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    id="confirm-password"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    error={confirmPasswordError}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm password"
                                    disabled={!editMode}
                                />
                                <FormHelperText
                                    error={confirmPasswordError}
                                    id="password-helper-text"
                                >
                                    {confirmPasswordError ? 'Passwords do not match' : ' '}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default UserAccount;