//This component was created using the example from React DnD documentation with a few style changes.
//https://react-dnd.github.io/react-dnd/examples/sortable/simple

import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { useTheme } from '@mui/material/styles';
import { Stack, Paper, Typography, Button, LinearProgress } from '@mui/material';

import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';

import { DnDCard as Card } from './DnDCard';
import usePrevious from '../../hooks/usePrevious';
import { DnDContainer as DnDContainerProps, DnDCardItem } from '../../types/DnD';

const DnDContainer: React.FC<DnDContainerProps> = ({
    header,
    subHeader,
    items = [],
    loading = false,
    cardStyle = undefined,
    revertChanges,
    saveChanges,
    onMove
}) => {
    const theme = useTheme();
    const [cards, setCards] = useState(items);
    const prevLoadingState = usePrevious(loading);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: DnDCardItem[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as DnDCardItem],
                ],
            }),
        );
        if (onMove) onMove();
        // eslint-disable-next-line
    }, [])

    const renderCard = useCallback(
        (card: { id: number; text: string }, index: number) => {
            return (
                <Card
                    key={card.id}
                    index={index}
                    id={card.id}
                    text={card.text}
                    moveCard={moveCard}
                    sx={cardStyle}
                />
            );
            // eslint-disable-next-line
        }, [])

    useEffect(() => {
        if (loading !== prevLoadingState) setCards(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    return (
        <>
            <Paper elevation={3} sx={{ padding: '1rem' }}>
                <Typography gutterBottom variant="h6">{header}</Typography>
                <Typography sx={{ margin: '1rem 0 2rem 0' }} variant='subtitle1' color={theme.palette.text.secondary}>{subHeader}</Typography>

                <DndProvider backend={HTML5Backend}>
                    <Stack spacing={1}>
                        {loading ? (
                            <LinearProgress color="inherit" />
                        ) : (
                            cards.map((card, i) => renderCard(card, i))
                        )}
                    </Stack>
                </DndProvider>
                <Stack direction='row' alignItems='flex-end' justifyContent='flex-end' gap={2} sx={{ marginTop: '2rem' }}>
                    {revertChanges && (
                        <Button
                            startIcon={<UndoIcon />}
                            onClick={() => {
                                setCards(items);
                                revertChanges();
                            }}
                        >
                            Revert
                        </Button>
                    )}

                    {saveChanges && (
                        <Button
                            variant="contained"
                            endIcon={<SaveIcon />}
                            onClick={() => saveChanges(cards)}
                        >
                            Save
                        </Button>
                    )}
                </Stack>
            </Paper>
        </>
    );
};

export default DnDContainer;