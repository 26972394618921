import React from "react";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from 'notistack';
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridRowId } from "@mui/x-data-grid";
import ServiceKeys from "../../../services/serviceKeys";
import * as providersService from '../../../services/providersService';
import { ServerError } from "../../../types/Error";
import { queryClient } from "../../../config/localStorage";
import { Provider } from "../../../types/Providers";

const useUpdateProviderMutation = (apiRef?: React.MutableRefObject<GridApiPro>) => {
    const { enqueueSnackbar } = useSnackbar();
    return useMutation({
        mutationFn: async ({ providerName, provider }: { providerName: string, provider: Provider }) => {
            await providersService.updateProvider(providerName, provider);
        },
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries([ServiceKeys.Providers, variables.providerName]);
            enqueueSnackbar(`Provider ${variables.providerName} record updated`, { variant: 'success' });
        },
        onError: (err: ServerError, variables) => {
            // Set the row to edit mode again if it fails to update the provider record
            if (apiRef) apiRef.current.startRowEditMode({ id: variables.provider.id as GridRowId });
            enqueueSnackbar(`Failed to update provider ${variables.providerName} record. ${err.response.data.log}`, { variant: 'error' });
        }
    });
}
export default useUpdateProviderMutation;