import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import SideMenu from '../../components/SideMenu';
import AccountMenu from '../../components/AccountMenu';

const PageWrapper: React.FC = () => {
    return (
        <Box display="flex">
            <SideMenu />
            <Container maxWidth={false} disableGutters style={{
                position: 'relative',
                paddingTop: '25px',
                paddingRight: '1rem',
                paddingBottom: '2rem'
            }}>
                <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}><AccountMenu /></Box>
                <Outlet />
            </Container>
        </Box>
    );
};

export default PageWrapper;