import axios from '../config/axios';
import { AxiosRequestHeaders } from 'axios';
import { isEmpty } from 'ramda';

import { AxiosParams } from '../types/API';

export async function getData(endpoint: string, params?: AxiosParams, headers?: AxiosRequestHeaders | undefined, filters?: string[]) {
    let result;
    if (!filters || isEmpty(filters)) {
        result = await axios.get(endpoint, { params, headers });
    } else {
        // @ts-ignore
        const nonFilterString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const filterString = filters.map(filter => filter).join('&');
        const endpointWithParams = endpoint + '?' + nonFilterString + '&' + filterString;
        result = await axios.get(endpointWithParams, { headers });
    }

    return returnResult(result);
}

export async function updateData(endpoint: string, payload: any, params?: AxiosParams) {
    const result = await axios.put(endpoint, payload, { params });

    return returnResult(result);
}

export async function postData(endpoint: string, payload?: any, params?: AxiosParams, headers?: AxiosRequestHeaders) {
    const result = await axios.post(endpoint, payload, { params, headers });

    return returnResult(result);
}

export async function deleteData(endpoint: string, payload?: any) {
    const data = payload ? { data: payload } : undefined;
    const result = await axios.delete(endpoint, data);

    return returnResult(result);
}

export async function patchData(endpoint: string, payload: any, params: AxiosParams) {
    const result = await axios.patch(endpoint, payload, { params });

    return returnResult(result);
}

function returnResult(result: any) {
    if (!result.status || result.status === 200 || ![401, 404, 500].includes(result.status)) {
        return result;
    } else {
        throw new Error(result);
    }
}

export function formatEndpoint(endpoint: string, data: any) {
    const regex = /{(.*?)}/g;
    const matches = endpoint.match(regex);

    if (!data && matches && matches.length > 0) {
        endpoint = endpoint.replace(/{{.*?}}/g, '').replace(/\/\//g, '//');

        return endpoint;
    }

    if (matches && matches.length) {
        matches.forEach((match) => {
            endpoint = endpoint.replace(
                new RegExp(match, 'g'),
                getProperty(match.replace(/{|}/g, ''), data)
            );
        });
    }

    if (endpoint.indexOf('{') > -1) {
        throw new Error(`You seem to be missing an expected property on this endpoint. Ensure you have the following: ${matches?.map(m => m)}`,);
    }

    return endpoint;
}

function getProperty(propertyName: string, obj: any) {
    let parts = propertyName.split('.'),
        length = parts.length,
        i,
        property = obj;

    for (i = 0; i < length; i++) {
        property = property[parts[i]];
    }

    return property;
}