export const PROPOSALS_STATS_OPTIONS = [
    {
        id: 'NUMBER_CREATED',
        text: 'Number of proposals created'
    },
    {
        id: 'NUMBER_CLOSED',
        text: 'Number of proposals closed'
    },
    {
        id: 'NUMBER_PUBLISHED',
        text: 'Number of proposals published'
    },
    {
        id: 'PROPOSALS_WITH_PROPERTY_REQUEST',
        text: 'Proposals with at least one viewed property request (i.e. successful proposals)'
    },
    {
        id: 'RATIO_PUBLISHED_VS_SUCCESSFUL',
        text: 'Ratio of published proposals vs successful proposals'
    },
    {
        id: 'TOTAL',
        text: 'Total number of recommendations used to create a published proposal'
    },
];

export const CLOSURE_FORMS_OPTIONS = [
    {
        id: 'CONTRACT_LENGTH',
        text: 'Contract length'
    },
    {
        id: 'PRICE',
        text: 'Price'
    },
    {
        id: 'PREVIOUS_CONTRACT_TYPE',
        text: 'Previous contract type'
    },
    {
        id: 'OCCUPATION_RATIO',
        text: 'Occupation ratio'
    },
    {
        id: 'FLOOR_OCCUPANCY',
        text: 'Floor occupancy (split or single)'
    },
    {
        id: 'LAYOUT_TYPE',
        text: 'Layout type'
    },
];