import React, { useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { extraPalette } from '../../themes/mui';
import SyfterLogo from '../../images/syfter/syfter-dark.svg';

interface AuthPaperProps {
    children: React.ReactNode;
}

const AuthPaper: React.FC<AuthPaperProps> = ({ children }) => {
    // set background color on mount
    useEffect(() => {
        document.body.style.backgroundColor = extraPalette.BLACK;
        // reset background as only needed on this login page
        return () => {
            document.body.style.backgroundColor = extraPalette.GREY1;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper sx={{ height: '675px', width: '575px' }}>
                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    padding="0 120px"
                >
                    <Box position="absolute" top="110px" width="300px"><img alt="Thirdway Logo" src={SyfterLogo}
                                                                            height="100%" width="100%" /></Box>
                    {children}
                </Box>
            </Paper>
        </Box>
    );
};

export default AuthPaper;