import React, { useState } from 'react';
import { Box, Avatar, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Typography, OutlinedInput } from '@mui/material';

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
      },
    },
  };

interface PlatformSettingsMultiItemSelectProps {
    title: string,
    label: string,
    items: { name: string, value: string, logo: string }[],
    value: string[],
    disabled?: boolean,
    onChange: (selected: string[]) => void
}

const PlatformSettingsMultiItemSelect: React.FC<PlatformSettingsMultiItemSelectProps> = ({ title, label, items, value, disabled, onChange }) => {

    const [selectedItems, setSelectedItems] = useState<string[]>(value || []);

    const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
        const { target: { value } } = event;
        // Either adds just the value or splits them by comma
        const values = typeof value === 'string' ? value.split(',') : value
        setSelectedItems(values);
        onChange(values);
    };

    return (
        <FormGroup sx={{ paddingTop: '10px' }}>
            <FormControlLabel
                labelPlacement='start'
                sx={{ justifyContent: 'flex-end', marginLeft: '0' }}
                disabled={disabled}
                label={<Typography variant='subtitle1'>{title}</Typography>}
                control={
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
                        <Select
                            labelId="multiple-checkbox-label"
                            id="multiple-checkbox"
                            multiple
                            value={selectedItems}
                            onChange={handleChange}
                            input={<OutlinedInput label={label} />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip 
                                            key={value}
                                            label={items?.find(item => item.value === value)?.name}
                                            avatar={
                                                <Avatar
                                                    alt={items?.find(item => item.value === value)?.name}
                                                    src={items?.find(item => item.value === value)?.logo}
                                                />
                                            }
                                        />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {items.map((item) => (
                                <MenuItem key={item.name} value={item.value}>
                                    <Checkbox checked={selectedItems.indexOf(item.value) > -1} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
            />
        </FormGroup>
    );
};

export default PlatformSettingsMultiItemSelect;
