import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Drawer as MuiDrawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SpeedIcon from '@mui/icons-material/Speed';
import GroupIcon from '@mui/icons-material/Group';
import ConstructionIcon from '@mui/icons-material/Construction';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import SettingsIcon from '@mui/icons-material/Settings';

import { ROUTES } from '../../constants';
import { extraPalette, extraTypography } from '../../themes/mui';
import SyfterLogo from '../../images/syfter/syfter-dark.svg';
import { isActiveRoute } from '../../utils/routes';

const drawerWidth = 230;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', { 
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: extraPalette.WHITE
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(2)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 10px)`,
    },
    backgroundColor: extraPalette.WHITE
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    padding: theme.spacing(0, 5)
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        position: 'relative',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const SideMenu: React.FC = () => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const navigate = useNavigate();

    const { pathname } = useLocation();
    const routeLocation = '/' + pathname.split('/')[1];

    const menuItems = [
        {
            text: 'Dashboard',
            icon: <SpeedIcon />,
            route: ROUTES.DASHBOARD,
        },
        {
            text: 'Platform Settings',
            icon: <SettingsIcon />,
            route: ROUTES.PLATFORM_SETTINGS
        },
        {
            text: 'Properties Index',
            icon: <ViewQuiltIcon />,
            route: ROUTES.CUSTOM_PROPERTIES_INDEX
        },
        {
            text: 'Providers',
            icon: <ElectricalServicesIcon />,
            route: ROUTES.PROVIDERS,
        },
        {
            text: 'UI Configuration',
            icon: <ConstructionIcon />,
            route: ROUTES.UI_CONFIGURATION,
        },
        {
            text: 'Roles',
            icon: <GroupIcon />,
            route: ROUTES.USER_MANAGEMENT,
            disabled: true
        },
        {
            text: 'Branding',
            icon: <ModeEditIcon />,
            route: ROUTES.BRANDING,
            disabled: true
        },
        {
            text: 'Sectors',
            icon: <HomeRepairServiceIcon />,
            route: ROUTES.SECTORS,
            disabled: true
        },
        {
            text: 'Signals',
            icon: <SignalCellularAltIcon />,
            route: ROUTES.SIGNALS,
            disabled: true
        }
    ];

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <Box position="relative" marginRight="4rem">
            <IconButton
                size="small"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                sx={{
                    position: 'fixed',
                    left: open ? '210px' : '37px',
                    top: '27px',
                    zIndex: 1201,
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                    border: '2px solid white',
                    boxShadow: '4px 4px 4px #00000029',
                    transition: `left ${open ? 225 : 195}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
                    '&-webkit-transition': `left ${open ? 225 : 195}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`,
                    '&:hover': {
                        backgroundColor: '#dfdfdf'
                    }
                }}
            >
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <img alt="Syfter Logo" src={SyfterLogo} height="100%" width="100%" />
                </DrawerHeader>
                <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
                    <List>
                        {menuItems.map(item => {
                            return (
                                <ListItemButton
                                    key={item.text}
                                    onClick={() => navigate(item.route)}
                                    sx={{ margin: '5px 0' }}
                                    disabled={item.disabled}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '45px',
                                            color: isActiveRoute(routeLocation, item.route) ? theme.palette.primary.main : '#5b72a0bf'
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText disableTypography>
                                        <Typography
                                            sx={{
                                                ...extraTypography.menu1,
                                                fontSize: '16px',
                                                color: isActiveRoute(routeLocation, item.route) ? theme.palette.primary.main : '#5b72a0bf'
                                            }}
                                        >
                                            {item.text}
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            );
                        })}
                    </List>
                    <List>
                        <ListItemButton
                            onClick={() => navigate(ROUTES.USER_MANAGEMENT)}
                            disabled
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: '45px',
                                    color: ROUTES.USER_MANAGEMENT === routeLocation ? theme.palette.primary.main : '#5b72a0bf'
                                }}
                            >
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Typography
                                    sx={{
                                        ...extraTypography.menu2,
                                        color: ROUTES.USER_MANAGEMENT === routeLocation ? theme.palette.primary.main : '#5b72a0bf'
                                    }}
                                >
                                    MANAGE USERS
                                </Typography>
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
        </Box>
    );
};

export default SideMenu;