import { useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack";
import ServiceKeys from "../../../services/serviceKeys";
import * as userService from '../../../services/currentUserService';
import { ServerError } from "../../../types/Error";

const useGetCurrentUser = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery({
        queryKey: [ServiceKeys.User],
        queryFn: userService.getUser,
        onError: (err: ServerError) => {
            enqueueSnackbar(`An error occurred while fetching the user ${err.response.data.log}`, { variant: 'error' });
        },
        retry: false
    });
}

export default useGetCurrentUser;