import { UiConfigConstant } from "../types/UiConfig";

export const UI_CONFIG_MAPPING: UiConfigConstant = {
    FEATURES: [
        { name: "feature_companies", displayName: "Feed", default: true },
        { name: "feature_news_flow", displayName: "News Flow", default: true },
        {
            name: "feature_transactions",
            displayName: "Transactions",
            default: true,
        },
        {
            name: "feature_recommendations",
            displayName: "Recommendations",
            default: true,
        },
        {
            name: "feature_transition_accepted_recommendation_to_crm",
            displayName: "Transition Recommendation to CRM",
            default: true,
        },
        { name: "feature_news", displayName: "News" },
        { name: "feature_custom_properties", displayName: "Custom Properties" },
        { name: "feature_tasks", displayName: "Tasks" },
        {
            name: "feature_pdf_extract",
            displayName: "PDF Extract",
            default: true,
        },
        {
            name: "feature_collections",
            displayName: "Collections",
            default: true,
        },
        {
            name: "feature_monitoring_tools",
            displayName: "Monitoring Tools",
            default: true,
        },
        {
            name: "feature_watchlists",
            displayName: "Watchlists",
            default: true,
        },
        {
            name: "feature_tags",
            displayName: "My Tags",
            default: true,
        },
        {
            name: "feature_quick_search_filter",
            displayName: "Quick Search filter",
            default: false,
        },
    ],
    PAGES: [
        {
            name: "dashboard",
            displayName: "Dashboard",
            tabs: [
                {
                    name: "dashboard_locked",
                    displayName: "Dashboard",
                    widgets: [
                        {
                            name: "dashboard_recent_news",
                            displayName: "Recent News",
                        },
                        {
                            name: "dashboard_recent_views",
                            displayName: "Recent Views",
                        },
                        {
                            name: "dashboard_latest_companies",
                            displayName: "Latest Companies",
                        },
                        {
                            name: "dashboard_todays_alerts",
                            displayName: "Today's Alerts",
                        },
                        {
                            name: "dashboard_assigned_companies",
                            displayName: "Assigned Companies",
                        },
                    ],
                },
            ],
        },
        {
            name: "companies",
            displayName: "Companies Table",
            tabs: [
                {
                    name: "companies_table_locked",
                    displayName: "Companies (main table)",
                    widgets: [
                        {
                            name: "companies_table_locked",
                            displayName: "Columns",
                            fields: [
                                {
                                    name: "companies_table_tags",
                                    displayName: "My Tags",
                                    default: true,
                                },
                                {
                                    name: "companies_table_headquarters",
                                    displayName: "Headquarters",
                                    default: true,
                                },
                                {
                                    name: "companies_table_employees",
                                    displayName: "Employees",
                                    default: true,
                                },
                                {
                                    name: "companies_table_funding",
                                    displayName: "Funding",
                                    default: true,
                                },
                                {
                                    name: "companies_table_stage",
                                    displayName: "Stage",
                                    default: true,
                                },
                                {
                                    name: "companies_table_revenue",
                                    displayName: "Revenue",
                                    default: true,
                                },
                                {
                                    name: "companies_table_net_sales",
                                    displayName: "Net Sales",
                                    default: false,
                                },
                                {
                                    name: "companies_table_cost_of_sales",
                                    displayName: "Cost of Sales",
                                    default: false,
                                },
                                {
                                    name: "companies_table_gross_profit",
                                    displayName: "Gross Profit",
                                    default: false,
                                },
                                {
                                    name: "companies_table_gross_profit_margin",
                                    displayName: "Gross Profit Margin",
                                    default: false,
                                },
                                {
                                    name: "companies_table_employee_remuneration",
                                    displayName: "Employee Remuneration",
                                    default: false,
                                },
                                {
                                    name: "companies_table_director_remuneration",
                                    displayName: "Director Remuneration",
                                    default: false,
                                },
                                {
                                    name: "companies_table_ebitda",
                                    displayName: "Ebitda",
                                    default: true,
                                },
                                {
                                    name: "companies_table_ebitda_margin",
                                    displayName: "Ebitda Margin",
                                    default: true,
                                },
                                {
                                    name: "companies_table_operating_profit",
                                    displayName: "Operating Profit",
                                    default: false,
                                },
                                {
                                    name: "companies_table_ebit",
                                    displayName: "Ebit",
                                    default: true,
                                },
                                {
                                    name: "companies_table_ebit_margin",
                                    displayName: "Ebit Margin",
                                    default: false,
                                },
                                {
                                    name: "companies_table_pretax_profit",
                                    displayName: "Pretax Profit",
                                    default: false,
                                },
                                {
                                    name: "companies_table_taxation",
                                    displayName: "Taxation",
                                    default: false,
                                },
                                {
                                    name: "companies_table_net_profit",
                                    displayName: "Net Profit",
                                    default: false,
                                },
                                {
                                    name: "companies_table_total_enterprise_value",
                                    displayName: "Enterprise Value",
                                    default: false,
                                },
                                {
                                    name: "companies_table_cash",
                                    displayName: "Cash",
                                    default: false,
                                },
                                {
                                    name: "companies_table_trade_debtors",
                                    displayName: "Trade Debtors",
                                    default: false,
                                },
                                {
                                    name: "companies_table_total_current_assets",
                                    displayName: "Total Current Assets",
                                    default: false,
                                },
                                {
                                    name: "companies_table_intangible_assets",
                                    displayName: "Tangible Assets",
                                    default: false,
                                },
                                {
                                    name: "companies_table_other_non_current_assets",
                                    displayName: "Other Non Current Assets",
                                    default: false,
                                },
                                {
                                    name: "companies_table_total_non_current_assets",
                                    displayName: "Total Non Current Assets",
                                    default: false,
                                },
                                {
                                    name: "companies_table_total_assets",
                                    displayName: "Total Assets",
                                    default: true,
                                },
                                {
                                    name: "companies_table_interest_payable",
                                    displayName: "Interest Payable",
                                    default: false,
                                },
                                {
                                    name: "companies_table_trade_payables",
                                    displayName: "Trade Payables",
                                    default: false,
                                },
                                {
                                    name: "companies_table_current_liabilities",
                                    displayName: "Current Liabilities",
                                    default: false,
                                },
                                {
                                    name: "companies_table_bank_overdrafts",
                                    displayName: "Bank Overdrafts",
                                    default: false,
                                },
                                {
                                    name: "companies_table_net_debt",
                                    displayName: "Net Debt",
                                    default: false,
                                },
                                {
                                    name: "companies_table_net_debt_issued",
                                    displayName: "Net Debt Issued",
                                    default: false,
                                },
                                {
                                    name: "companies_table_long_term_liabilities",
                                    displayName: "Long Term Liabilities",
                                    default: false,
                                },
                                {
                                    name: "companies_table_net_assets",
                                    displayName: "Net Assets",
                                    default: false,
                                },
                                {
                                    name: "companies_table_total_equity",
                                    displayName: "Total Equity",
                                    default: false,
                                },
                                {
                                    name: "companies_table_retained_earnings",
                                    displayName: "Retained Earnings",
                                    default: false,
                                },
                                {
                                    name: "companies_table_working_capital",
                                    displayName: "Working Capital",
                                    default: false,
                                },
                                {
                                    name: "companies_table_capital_employed",
                                    displayName: "Capital Employed",
                                    default: false,
                                },
                                {
                                    name: "companies_table_liquidity_ratio",
                                    displayName: "Liquidity Ratio",
                                    default: false,
                                },
                                {
                                    name: "companies_table_net_debt_ebitda_ratio",
                                    displayName: "Net Debt Ebitda Ratio",
                                    default: false,
                                },
                                {
                                    name: "companies_table_established",
                                    displayName: "Established",
                                    default: true,
                                },
                                {
                                    name: "companies_table_custom_properties",
                                    displayName: "Custom Properties",
                                    default: false,
                                },
                                {
                                    name: "companies_table_ownership",
                                    displayName: "Ownership",
                                    default: false,
                                },
                            ],
                        },
                        {
                            name: "companies_table_name_cell",
                            displayName: "Cell Name",
                            fields: [
                                {
                                    name: "companies_table_name_cell_primary_company_flag",
                                    displayName: "Primary Company Flag",
                                },
                            ],
                        },
                        {
                            name: "companies_table_advanced_filters",
                            displayName: "Advanced Filters",
                            fields: [
                                {
                                    name: "companies_table_advanced_filters_primary_company",
                                    displayName: "Primary Company",
                                },
                                {
                                    name: "companies_table_advanced_filters_group_structure",
                                    displayName: "Group Structure",
                                },
                            ],
                        },
                        {
                            name: "companies_table_quick_search_filter",
                            displayName: "Quick Search filter",
                            default: true,
                        },
                        {
                            name: "companies_table_alert",
                            displayName: "Display Alerts Icon",
                        },
                    ],
                },
            ],
        },
        {
            name: "recommendations",
            displayName: "Recommendations",
            tabs: [
                {
                    name: "recommendations_recommended",
                    displayName: "Recommended",
                    widgets: [
                        {
                            name: "recommendations_recommended_table_locked",
                            displayName: "Columns",
                            fields: [
                                {
                                    name: "recommendations_recommended_table_recommended_at",
                                    displayName: "Recommended At",
                                },
                                {
                                    name: "recommendations_recommended_table_confidence",
                                    displayName: "Confidence",
                                },
                                {
                                    name: "recommendations_recommended_table_source",
                                    displayName: "Source",
                                },
                            ],
                        },
                        {
                            name: "recommendations_recommended_table_name_cell",
                            displayName: "Cell Name",
                            fields: [
                                {
                                    name: "recommendations_recommended_table_name_cell_primary_company_flag",
                                    displayName: "Primary Company Flag",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "recommendations_actioned",
                    displayName: "Actioned",
                    widgets: [
                        {
                            name: "recommendations_actioned_table_locked",
                            displayName: "Columns",
                            fields: [
                                {
                                    name: "rec_actioned_table_action",
                                    displayName: "Action",
                                },
                                {
                                    name: "rec_actioned_table_actioned_at",
                                    displayName: "Actioned At",
                                },
                                {
                                    name: "rec_actioned_table_actioned_by",
                                    displayName: "Actioned By",
                                },
                                {
                                    name: "rec_actioned_table_recommended_at",
                                    displayName: "Recommended At",
                                },
                                {
                                    name: "rec_actioned_table_confidence",
                                    displayName: "Confidence",
                                },
                                {
                                    name: "rec_actioned_table_source",
                                    displayName: "Source",
                                },
                            ],
                        },
                        {
                            name: "recommendations_actioned_table_name_cell",
                            displayName: "Cell Name",
                            fields: [
                                {
                                    name: "recommendations_actioned_table_name_cell_primary_company_flag",
                                    displayName: "Primary Company Flag",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            name: "transactions",
            displayName: "Transactions",
            tabs: [],
            default: true,
        },
        {
            name: "news_flow",
            displayName: "News Flow",
            tabs: [
                {
                    name: "news_flow_table_locked",
                    displayName: "Columns",
                    widgets: [
                        {
                            name: "news_flow_table_article_overview",
                            displayName: "Article Overview",
                            fields: [
                                {
                                    name: "news_flow_table_article_overview_primary_company_flag",
                                    displayName: "Primary Company Flag",
                                },
                            ],
                        },
                        {
                            name: "news_flow_table_advanced_filters",
                            displayName: "Advanced Filters",
                            fields: [
                                {
                                    name: "news_flow_table_advanced_filters_primary_company",
                                    displayName: "Primary Company",
                                },
                                {
                                    name: "news_flow_table_advanced_filters_group_structure",
                                    displayName: "Group Structure",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            name: "company_profile",
            displayName: "Company Profile",
            tabs: [
                {
                    name: "company_profile_overall_locked",
                    displayName: "Columns",
                    widgets: [
                        {
                            name: "company_profile_overall_fields",
                            displayName: "Fields",
                            fields: [
                                {
                                    name: "company_profile_overall_primary_company_flag",
                                    default: true,
                                    displayName: "Primary Company Flag",
                                },
                                {
                                    name: "company_profile_overall_legal_name",
                                    default: true,
                                    displayName: "Legal Name",
                                },
                                {
                                    name: "company_profile_overall_trading_status",
                                    default: false,
                                    displayName: "Trading Status",
                                },
                                {
                                    name: "company_profile_overall_ownership",
                                    default: true,
                                    displayName: "Ownership",
                                },
                                {
                                    name: "company_profile_overall_stage",
                                    default: true,
                                    displayName: "Stage",
                                },
                                {
                                    name: "company_profile_overall_headquarters",
                                    default: true,
                                    displayName: "Headquarters",
                                },
                                {
                                    name: "company_profile_overall_business_valuation",
                                    default: true,
                                    displayName: "Business Valuation",
                                },
                                {
                                    name: "company_profile_overall_business_valuation_range",
                                    default: true,
                                    displayName: "Business Valuation Range",
                                },
                                {
                                    name: "company_profile_overall_headcount",
                                    default: true,
                                    displayName: "Headcount",
                                },
                                {
                                    name: "company_profile_overall_founded",
                                    default: true,
                                    displayName: "Founded",
                                },
                                {
                                    name: "company_profile_overall_company_number",
                                    default: true,
                                    displayName: "Company Number",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "company_profile_overview",
                    displayName: "Overview",
                    widgets: [
                        {
                            name: "company_profile_overview_engagement",
                            displayName: "Engagement",
                        },
                        {
                            name: "company_profile_overview_financial",
                            displayName: "Financial",
                        },
                        {
                            name: "company_profile_overview_news",
                            displayName: "News",
                        },
                        {
                            name: "company_profile_overview_location",
                            displayName: "Location",
                        },
                        {
                            name: "company_profile_overview_crm_properties",
                            displayName: "CRM Properties",
                        },
                        {
                            name: "company_profile_overview_sectors",
                            displayName: "Sectors",
                        },
                        {
                            name: "company_profile_overview_headcount",
                            displayName: "Headcount",
                        },
                        {
                            name: "company_profile_overview_latest_funding_round",
                            displayName: "Latest Funding Round",
                        },
                        {
                            name: "company_profile_overview_recommendation",
                            displayName: "Recommendation",
                        },
                        {
                            name: "company_profile_overview_custom_properties",
                            displayName: "Custom Properties",
                        },
                        {
                            name: "company_profile_overview_keywords",
                            displayName: "Keywords",
                        },
                        {
                            name: "company_profile_overview_people",
                            displayName: "People",
                        },
                        {
                            name: "company_profile_overview_assigned_users",
                            displayName: "Assigned Users",
                        },
                        {
                            name: "company_profile_overview_tasks",
                            displayName: "Tasks",
                        },
                        {
                            name: "company_profile_overview_external_links",
                            displayName: "External Links",
                        },
                        {
                            name: "company_profile_overview_contact",
                            displayName: "Contact",
                        },
                        {
                            name: "company_profile_overview_group_parents",
                            default: false,
                            displayName: "Group Parents",
                            fields: [
                                {
                                    name: "company_profile_overview_group_parents_primary_company_flag",
                                    displayName: "Primary Company Flag",
                                    default: true,
                                },
                            ],
                        },
                        {
                            name: "company_profile_overview_shareholders",
                            default: false,
                            displayName: "Shareholders",
                        },
                        {
                            name: "company_profile_overview_collections",
                            default: true,
                            displayName: "Collections",
                        },
                    ],
                },
                {
                    name: "company_profile_financials",
                    displayName: "Financials",
                    widgets: [
                        {
                            name: "company_profile_financials_financial_accounts",
                            displayName: "Financial Accounts",
                        },
                        {
                            name: "company_profile_financials_financials_over_time",
                            displayName: "Financials Over Time",
                        },
                        {
                            name: "company_profile_financials_income_statement",
                            displayName: "Income Statement",
                        },
                        {
                            name: "company_profile_financials_balance_sheet",
                            displayName: "Balance Sheet",
                        },
                    ],
                },
                {
                    name: "company_profile_news",
                    displayName: "News",
                },
                {
                    name: "company_profile_transactions",
                    displayName: "Transactions",
                },
                {
                    name: "company_profile_related",
                    displayName: "Related",
                    widgets: [
                        {
                            name: "company_profile_related_relationship_locked",
                            displayName: "Relationship",
                            fields: [
                                {
                                    name: "company_profile_related_relationship_competitors",
                                    displayName: "Competitors",
                                },
                                {
                                    name: "company_profile_related_relationship_customers",
                                    displayName: "Customers",
                                },
                                {
                                    name: "company_profile_related_relationship_nearby",
                                    displayName: "Nearby",
                                },
                                {
                                    name: "company_profile_related_relationship_suppliers",
                                    displayName: "Suppliers",
                                },
                            ],
                        },
                        {
                            name: "company_profile_related_table_locked",
                            displayName: "Columns",
                            fields: [
                                {
                                    name: "company_profile_related_table_sector",
                                    displayName: "Sector",
                                },
                                {
                                    name: "company_profile_related_table_country",
                                    displayName: "Country",
                                },
                                {
                                    name: "company_profile_related_table_type",
                                    displayName: "Type",
                                },
                                {
                                    name: "company_profile_related_table_data_source",
                                    displayName: "Data Source",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "company_profile_people",
                    displayName: "People",
                    widgets: [
                        {
                            name: "company_profile_people_headcount_over_time",
                            displayName: "Headcount Over time",
                        },
                        {
                            name: "company_profile_people_employees",
                            displayName: "Employees",
                            fields: [
                                {
                                    name: "company_profile_people_employees_table_job_title",
                                    displayName: "Job Title",
                                },
                                {
                                    name: "company_profile_people_employees_table_nationality",
                                    displayName: "Nationality",
                                },
                                {
                                    name: "company_profile_people_employees_table_role_type",
                                    displayName: "Role Type",
                                },
                                {
                                    name: "company_profile_people_employees_table_role_start_date",
                                    displayName: "Start Date",
                                },
                                {
                                    name: "company_profile_people_employees_table_role_end_date",
                                    displayName: "End Date",
                                },
                                {
                                    name: "company_profile_people_employees_table_data_source",
                                    displayName: "Data Source",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "company_profile_shareholders",
                    displayName: "Shareholders",
                    widgets: [
                        {
                            name: "company_profile_shareholders_table_locked",
                            displayName: "Columns",
                            fields: [
                                {
                                    name: "company_profile_shareholders_table_percentage_of_shares",
                                    displayName: "Percentage of Shares",
                                },
                                {
                                    name: "company_profile_shareholders_table_class",
                                    displayName: "Class",
                                },
                                {
                                    name: "company_profile_shareholders_table_number_of_shares",
                                    displayName: "Number of Shares",
                                },
                                {
                                    name: "company_profile_shareholders_table_date_from",
                                    displayName: "Date From",
                                },
                                {
                                    name: "company_profile_shareholders_table_data_source",
                                    displayName: "Data Source",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "company_profile_documents",
                    displayName: "Documents",
                    default: true,
                },
                {
                    name: "company_profile_web",
                    displayName: "Web",
                    widgets: [
                        {
                            name: "company_profile_web_datasources_locked",
                            displayName: "Datasources Columns",
                            fields: [
                                {
                                    name: "company_profile_web_datasources_google_trends",
                                    displayName: "Google Trends",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "company_profile_recommendations",
                    displayName: "Recommendations",
                    widgets: [],
                },
                {
                    name: "company_profile_collections",
                    displayName: "Collections",
                    widgets: [],
                    default: true,
                },
            ],
        },
        {
            name: "collections",
            displayName: "Collections",
            tabs: [],
            default: true,
        },
        {
            name: "monitoring_tools",
            displayName: "Monitoring Tools",
            tabs: [],
            default: true,
        },
        {
            name: "watchlists",
            displayName: "Watchlists",
            tabs: [],
            default: true,
        },
    ],
};

export const HAS_TOGGLE = [
    "dashboard",
    "companies",
    "feature_tags",
    "feature_funding",
    "feature_tasks",
    "feature_recommendations",
    "recommendations",
    "transactions",
    "feature_companies",
    "feature_news_flow",
    "news_flow",
    "feature_pdf_extract",
    "dashboard_todays_alerts",
    "dashboard_assigned_companies",
    "companies_table_tags",
    "companies_table_headquarters",
    "companies_table_employees",
    "companies_table_funding",
    "companies_table_stage",
    "companies_table_revenue",
    "companies_table_ebitda",
    "companies_table_ebitda_margin",
    "companies_table_ebit",
    "companies_table_total_assets",
    "companies_table_established",
    "companies_table_custom_properties",
    "companies_table_net_sales",
    "companies_table_cost_of_sales",
    "companies_table_gross_profit",
    "companies_table_employee_remuneration",
    "companies_table_director_remuneration",
    "companies_table_operating_profit",
    "companies_table_pretax_profit",
    "companies_table_taxation",
    "companies_table_net_profit",
    "companies_table_gross_profit_margin",
    "companies_table_ebit_margin",
    "companies_table_total_enterprise_value",
    "companies_table_cash",
    "companies_table_trade_debtors",
    "companies_table_total_current_assets",
    "companies_table_intangible_assets",
    "companies_table_other_non_current_assets",
    "companies_table_total_non_current_assets",
    "companies_table_interest_payable",
    "companies_table_trade_payables",
    "companies_table_current_liabilities",
    "companies_table_bank_overdrafts",
    "companies_table_net_debt",
    "companies_table_net_debt_issued",
    "companies_table_long_term_liabilities",
    "companies_table_net_assets",
    "companies_table_total_equity",
    "companies_table_retained_earnings",
    "companies_table_working_capital",
    "companies_table_capital_employed",
    "companies_table_liquidity_ratio",
    "companies_table_net_debt_ebitda_ratio",
    "company_profile_overview_latest_funding_round",
    "company_profile_overview_tasks",
    "company_profile_overview_group_parents",
    "company_profile_overview_group_parents_primary_company_flag",
    "company_profile_overview_shareholders",
    "company_profile_overview_recommendation",
    "company_profile_recommendations",
    "company_profile_overview_people",
    "company_profile_people",
    "company_profile_web",
    "company_profile_shareholders",
    "company_profile_web_datasources_google_trends",
    "company_profile_related_relationship_competitors",
    "company_profile_related_relationship_customers",
    "company_profile_related_relationship_suppliers",
    "companies_table_ownership",
    "company_profile_overview",
    "company_profile_overall_legal_name",
    "company_profile_transactions",
    "company_profile_financials",
    "company_profile_overall_ownership",
    "company_profile_overall_stage",
    "company_profile_overall_headquarters",
    "company_profile_overall_headcount",
    "company_profile_overall_founded",
    "company_profile_overall_company_number",
    "company_profile_overview_financial",
    "company_profile_overview_engagement",
    "company_profile_overview_news",
    "company_profile_overview_location",
    "company_profile_overview_external_links",
    "company_profile_overview_custom_properties",
    "company_profile_overview_crm_properties",
    "company_profile_overview_sectors",
    "company_profile_overview_keywords",
    "company_profile_overview_contact",
    "company_profile_overview_headcount",
    "company_profile_overview_assigned_users",
    "company_profile_overall_primary_company_flag",
    "companies_table_name_cell_primary_company_flag",
    "companies_table_quick_search_filter",
    "companies_table_advanced_filters_primary_company",
    "companies_table_advanced_filters_group_structure",
    "news_flow_table_article_overview_primary_company_flag",
    "news_flow_table_advanced_filters_primary_company",
    "news_flow_table_advanced_filters_group_structure",
    "company_profile_news",
    "company_profile_related",
    "company_profile_overview",
    "recommendations_recommended_table_name_cell_primary_company_flag",
    "recommendations_actioned_table_name_cell_primary_company_flag",
    "company_profile_overall_trading_status",
    "collections",
    "monitoring_tools",
    "feature_collections",
    "feature_monitoring_tools",
    "companies_table_alert",
    "feature_transition_accepted_recommendation_to_crm",
    "company_profile_documents",
    "company_profile_overview_collections",
    "company_profile_overall_business_valuation",
    "company_profile_overall_business_valuation_range",
    "watchlists",
    "feature_watchlists",
    "feature_transactions",
    "feature_quick_search_filter",
    "company_profile_collections",
];
