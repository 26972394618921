import { useQuery } from "@tanstack/react-query"
import { useSnackbar } from "notistack";
import ServiceKeys from "../../../services/serviceKeys";
import * as platformSettingsService from '../../../services/platformSettingsService';
import { ServerError } from "../../../types/Error";

const useGetPlatformSettings = () => {
    const { enqueueSnackbar } = useSnackbar();

    return useQuery({
        queryKey: [ServiceKeys.PlatformSettings],
        queryFn: platformSettingsService.getAllPlatformSettings,
        onError: (err: ServerError) => {
            enqueueSnackbar(`An error occurred while fetching the platform settings ${err.response.data.log}`, { variant: 'error' });
        },
        retry: false
    });
}

export default useGetPlatformSettings;