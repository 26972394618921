const hostname = process.env.NODE_ENV;
// Need to remove the .admin at the begining of the URL so that it can point to the BE API e.g. api.syfter.ai
export const API_ROOT = hostname === 'development' ? 'http://localhost:4000/api' : `https://api.${window.location.host.replace('admin.','')}/api`;
export const API_ROOT_ADMIN = hostname === 'development' ? 'http://localhost:4000/api/admin' : `https://api.${window.location.host.replace('admin.','')}/api/admin`;
export const API_ROOT_STATIC = hostname === 'development' ? 'http://localhost:4000/static' : `https://api.${window.location.hostname.replace('admin.','')}/static`;

export const API = {
    auth: {
        login: `${API_ROOT_ADMIN}/auth/login`,
        invite_user: `${API_ROOT}/auth/invite`,
        accept_invite: `${API_ROOT}/auth/invite/{id}/{token}`,
        invite_register: `${API_ROOT}/auth/register`,
        reset_password: `${API_ROOT}/auth/reset-password`,
        submit_reset_password: `${API_ROOT}/auth/reset-password/{id}/{token}`,
        roles: `${API_ROOT_ADMIN}/auth/roles`
    },
    users: {
        current_user: `${API_ROOT_ADMIN}/profile`,
        all: `${API_ROOT_ADMIN}/users`,
        user: `${API_ROOT}/users/{userId}`
    },
    ui_config: {
        fields: `${API_ROOT_ADMIN}/ui-configuration/fields`,
        pages: `${API_ROOT_ADMIN}/ui-configuration/pages`,
        tabs: `${API_ROOT_ADMIN}/ui-configuration/tabs`,
        widgets: `${API_ROOT_ADMIN}/ui-configuration/widgets`,
        renames: `${API_ROOT_ADMIN}/ui-configuration-renames`,
        deleteRename: `${API_ROOT_ADMIN}/ui-configuration-renames/{id}`,
    },
    platform_settings: {
        all: `${API_ROOT_ADMIN}/platform-settings`
    },
    custom_properties: {
        definitions: `${API_ROOT_ADMIN}/custom-property-definitions`,
        definition: `${API_ROOT_ADMIN}/custom-property-definitions/{definitionId}`,
        admin_definition: `${API_ROOT_ADMIN}/custom-property-definitions/{definitionId}`
    },
    config: {
        config: `${API_ROOT}/config`
    },
    providers: {
        all: `${API_ROOT_ADMIN}/providers`,
        provider: `${API_ROOT_ADMIN}/providers/{tableName}`,
        update_provider: `${API_ROOT_ADMIN}/providers/{tableName}/{id}`
    }
};