import React, { useState } from "react";
import { Box, Button, styled } from "@mui/material";
import {
    DataGridPro,
    GridColumns,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    useGridApiRef,
    GridRowParams,
    MuiEvent,
    GridEventListener,
    GridRowId,
    GridEvents,
    GridRowModel,
    GridPreProcessEditCellProps,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { isEmpty } from "ramda";

import { UiConfigRename } from "../../../types/UiConfig";
import ConfirmDelete from "../../Modals/ConfirmDelete";
import useUpdateUiConfigRenamesMutation from "../../../hooks/query/uiConfig/useUpdateUiConfigRenamesMutation";

interface UiConfigRenamesProps {
    data: UiConfigRename[] | undefined | null;
    pending: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .MuiFormGroup-options": {
        alignItems: "center",
        paddingBottom: theme.spacing(1),
        "& > div": {
            minWidth: 100,
            margin: theme.spacing(2),
            marginLeft: 0,
        },
    },
    "& .MuiDataGrid-cell--editing": {
        backgroundColor: "rgb(255,215,115, 0.19)",
        color: "#1a3e72",
        "& .MuiInputBase-root": {
            height: "100%",
        },
    },
    "& .Mui-error": {
        backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
        color: theme.palette.error.main,
        height: "100%",
    },
}));

const UiConfigRenames: React.FC<UiConfigRenamesProps> = ({ data, pending }) => {
    const apiRef = useGridApiRef();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogRenameId, setDeleteDialogRenameId] = useState(0);
    const { mutate: updateUiConfigurationRenames } =
        useUpdateUiConfigRenamesMutation();

    const handleRowEditStart = (
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<GridEvents.rowEditStop> = (
        params,
        event
    ) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => (event: React.MouseEvent) => {
        apiRef.current.startRowEditMode({ id });
        event.stopPropagation();
    };

    const handleSaveClick =
        (id: GridRowId) => async (event: React.MouseEvent) => {
            event.stopPropagation();
            await apiRef.current.stopRowEditMode({ id });
        };

    const handleDeleteClick = (id: GridRowId) => (event: React.MouseEvent) => {
        event.stopPropagation();
        setDeleteDialogRenameId(id as number);
        setOpenDeleteDialog(true);
    };

    const handleRenameDelete = (id: number) => {
        setOpenDeleteDialog(false);
        updateUiConfigurationRenames({
            uiConfigRename: { id } as UiConfigRename,
            deleteItem: true,
        });
        apiRef.current.updateRows([{ id, _action: "delete" }]);
    };

    const handleCancelClick =
        (id: GridRowId) => async (event: React.MouseEvent) => {
            event.stopPropagation();
            await apiRef.current.stopRowEditMode({
                id,
                ignoreModifications: true,
            });

            const row = apiRef.current.getRow(id);
            if (row?.isNew) {
                apiRef.current.updateRows([{ id, _action: "delete" }]);
            }
        };

    const processRowUpdate = async (rowData: GridRowModel) => {
        updateUiConfigurationRenames({
            uiConfigRename: rowData as UiConfigRename,
        });
        return { ...rowData, isNew: false };
    };

    const handleNewRow = () => {
        // Need to get all the rows and if there aren't any currently then return an array of [0]
        const rowIds =
            apiRef.current.getAllRowIds().length > 0
                ? apiRef.current.getAllRowIds()
                : [0];
        const maxId = Math.max(...(rowIds as number[]));
        const id = maxId + 1;

        // Set some default values for the row to prevent MUI logging warnings
        apiRef.current.updateRows([
            {
                id,
                created_at: Date.now(),
                updated_at: Date.now(),
                isNew: true,
            },
        ]);
        apiRef.current.startRowEditMode({ id });

        // Wait for the grid to render with the new row
        setTimeout(() => {
            apiRef.current.scrollToIndexes({
                rowIndex: apiRef.current.getRowsCount() - 1,
            });
            apiRef.current.setCellFocus(id, "identifier");
        });
    };

    const columns: GridColumns = [
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = apiRef.current.getRowMode(id) === "edit";

                if (isInEditMode) {
                    return [
                        //@ts-ignore
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                            color="primary"
                        />,
                        //@ts-ignore
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    //@ts-ignore
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    //@ts-ignore
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        { field: "id", type: "number" },
        {
            field: "identifier",
            width: 250,
            editable: true,
            type: "singleSelect",
            valueOptions: [
                "salesforce_interaction",
                "primary_company",
                "watchlist",
            ],
            valueGetter: ({ value }) =>
                value ? value : "salesforce_interaction",
        },
        {
            field: "singular_name",
            headerName: "singular name",
            width: 170,
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError =
                    params.props.value === undefined ||
                    isEmpty(params.props.value);
                return { ...params.props, error: hasError };
            },
            type: "string",
        },
        {
            field: "plural_name",
            headerName: "plural name",
            width: 170,
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError =
                    params.props.value === undefined ||
                    isEmpty(params.props.value);
                return { ...params.props, error: hasError };
            },
            type: "string",
        },
        {
            field: "created_at",
            headerName: "created at",
            width: 170,
            type: "date",
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: "updated_at",
            headerName: "updated at",
            width: 170,
            type: "date",
            valueGetter: ({ value }) => value && new Date(value),
        },
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton
                    touchRippleRef={undefined}
                    onResize={undefined}
                    onResizeCapture={undefined}
                />
                <GridToolbarFilterButton
                    onResize={undefined}
                    onResizeCapture={undefined}
                />
                <GridToolbarDensitySelector
                    touchRippleRef={undefined}
                    onResize={undefined}
                    onResizeCapture={undefined}
                />
                <GridToolbarExport touchRippleRef={undefined} />
                <Button
                    variant="text"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => handleNewRow()}
                >
                    Add New Rename
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <StyledBox display="flex">
            <DataGridPro
                rows={data || []}
                columns={columns}
                loading={pending}
                autoHeight
                apiRef={apiRef}
                editMode="row"
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                getRowId={(row) => row.id}
                experimentalFeatures={{ newEditingApi: true }}
                components={{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    pinnedColumns: { left: ["actions"] },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
            />
            <ConfirmDelete
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={() => handleRenameDelete(deleteDialogRenameId)}
                modalSubheading={"Are you sure you want to delete this rename?"}
            />
        </StyledBox>
    );
};

export default UiConfigRenames;
